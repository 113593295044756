/**
 * Primitives are base level components that handle style related props. These are
 * the basis for all components in the library and allow higher level components to
 * be composed of a common set of functionality with predictable rules.
 * Primitives styles can be extended using styled-components. All primitive
 * components accept an "tag" prop which will dictate the underlying element.
 * Primitives components all automatically remove style related props from being
 * passed any further down the component tree.
 */
import Button from "./button";
import Box from "./box";
import Flex from "./flex";
import Image from "./image";
import Input from "./input";
import RawHtml from "./raw-html";
import Text from "./text";
const Primitives = {
  Box,
  Button,
  Flex,
  Image,
  Input,
  RawHtml,
  Text
};
export default Primitives;
