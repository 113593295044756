import React from "react";
import Button from "../button";
import { colors } from "../utils/theme";
import { func, number } from "prop-types";
import LessThan from "../glyphs/elements/less-than";
import MoreThan from "../glyphs/elements/more-than";
import ReactPaginate from "react-paginate";
import "./pagination.css";

const propTypes = {
  /** Hanlder function when page changes */
  changeHandler: func,
  /** trigger page change */
  forcePage: number,
  /** Intial selected page */
  initialPage: number,
  /** Number of  Last pages to be displayed after label */
  marginPagesDisplayed: number,
  /** Total number of pages */
  pageCount: number,
  /** Number of pages to be displayed before break label */
  pageRangeDisplayed: number
};

const Pagination = ({
  changeHandler,
  initialPage,
  forcePage,
  marginPagesDisplayed,
  pageCount,
  pageRangeDisplayed
}) => (
  <ReactPaginate
    activeClassName="activePage"
    breakLabel="..."
    breakClassName="breakContainer"
    breakLinkClassName="breakLink"
    containerClassName="paginateContainer"
    initialPage={initialPage - 1}
    forcePage={forcePage}
    marginPagesDisplayed={marginPagesDisplayed}
    nextLabel={<Button glyph={<MoreThan fill={colors.primary[4]} />} />}
    nextClassName="arrow nextArrow"
    onPageChange={changeHandler}
    pageClassName="listContainer"
    pageCount={pageCount}
    pageLinkClassName="pageLink"
    previousLabel={<Button glyph={<LessThan fill={colors.primary[4]} />} />}
    previousClassName="arrow previousArrow"
    pageRangeDisplayed={pageRangeDisplayed}
  />
);
Pagination.propTypes = propTypes;
export default Pagination;
