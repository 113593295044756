import React from "react";
import { array, bool, func, number, oneOfType, string } from "prop-types";
import Primitives from "../primitives";
import Caret from "../glyphs/elements/caret";
import styled from "styled-components";
import { colors } from "../utils/theme";
import ListDropdown from "../list-dropdown";
import Input from "../input";

const propTypes = {
  /** What background color to use*/
  backgroundColor: string,
  /* handler on for search dropdowns */
  blurHandler: func,
  /** Border around the button */
  border: string,
  /** Border color */
  borderColor: string,
  /** Border radius for button */
  borderRadius: number,
  /**  name of the dropdown item class*/
  className: string,
  /** false if caret not required default value true*/
  caret: bool,
  /** Click handler for caret */
  clickHandler: func,
  /** Input change handler for search */
  changeHandler: func,
  /** State of dropdown if it is collapsed or not */
  collapsed: bool,
  /** Text color of the button */
  color: string,
  /** Dropdown is disabled or not */
  disabled: bool,
  /** Header for dropdown to be displayed befor collapsing */
  defaultValue: string,
  /** Prop for enabling lazy loading */
  enableLazyLoading: bool,
  /** focus handler for search */
  focusHandler: func,
  /** What Font family should button text use*/
  fontFamily: string,
  /** What font size should be used on button text */
  fontSize: number,
  /** Font styling for button text */
  fontStyle: string,
  /** What fontweight should be applied to button text */
  fontWeight: string,
  /** Id for forms */
  id: string,
  /** line height for texts in button */
  lineHeight: number,
  /** list min width  **/
  listWidth: oneOfType([number, string]),
  /** list max height */
  listMaxHeight: oneOfType([array, number, string]),
  /** list max width */
  listMaxWidth: oneOfType([array, number, string]),
  /** Name for dropdown */
  name: string,
  /**options when dropdown collapse */
  options: oneOfType([array, string]),
  /**placeholder for input */
  placeholder: string,
  /** search keyword */
  search: string,
  /** searchable dropdown  */
  searchable: bool,
  /** Handler function for dropdown options */
  selectHandler: func,
  /** Selected options for dropdown */
  selectedOptions: array,
  /** If dropdown is of multiselect or single select */
  selectType: string,
  /** whether shadow Required for Dropdown list */
  shadowRequired: bool
};
const defaultProps = {
  backgroundColor: "white",
  border: "1px solid",
  borderRadius: 4,
  caret: true,
  color: colors.text[2],
  disabled: false,
  enableLazyLoading: true,
  fontFamily: "Lato",
  fontSize: 1,
  fontStyle: "normal",
  fontWeight: "normal",
  lineHeight: 1.2,
  shadowRequired: true
};
export const StyledSelect = styled(Primitives.Flex)`
  box-sizing: border-box;
  appearance: none;
  outline: none;
  &:hover {
    border-color: ${props => !props.disabled && colors.text[1]};
    color: ${props => !props.disabled && colors.text[1]};
  }
`;
const CaretContainer = styled(Primitives.Box)`
  pointer-events: none;
`;

const Dropdown = ({
  backgroundColor,
  border,
  blurHandler,
  borderColor,
  borderRadius,
  clickHandler,
  changeHandler,
  collapsed,
  color,
  caret,
  className,
  disabled,
  defaultValue,
  enableLazyLoading,
  focusHandler,
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  id,
  lineHeight,
  listMaxHeight,
  listMaxWidth,
  listWidth,
  name,
  options,
  placeholder,
  search,
  searchable,
  selectedOptions = [],
  selectHandler,
  selectType,
  shadowRequired
}) => {
  // const selected =
  //   options &&
  //   options.filter(option => option.selected === true)[0] &&
  //   options.filter(option => option.selected === true)[0].text;
  // const multiSelected =
  //   options &&
  //   options
  //     .filter(option => option.selected === true)
  //     .map(option => option.text);
  return (
    <Primitives.Box position="relative" width={1}>
      <StyledSelect
        backgroundColor={backgroundColor}
        borderRadius={borderRadius}
        color={selectedOptions.length > 0 ? colors.text[0] : color}
        disabled={disabled}
        fontFamily={fontFamily}
        fontSize={fontSize}
        fontStyle={fontStyle}
        fontWeight={fontWeight}
        id={id}
        lineHeight={lineHeight}
        name={name}
        onClick={clickHandler}
        padding={12}
        position="relative"
        width="auto"
      >
        <Primitives.Flex maxHeight={14} maxWidth={200} overflowX="auto">
          {selectedOptions.length > 0
            ? selectedOptions.map((selectedOption, index) => (
                <Primitives.Text mr={1} key={index}>
                  {selectedOption}
                </Primitives.Text>
              ))
            : defaultValue
            ? defaultValue
            : ""}
        </Primitives.Flex>
        {caret && (
          <CaretContainer
            position="absolute"
            right="0.44em"
            top="1.13em"
            zIndex={1}
          >
            <Caret width={12} height={8} fill={colors.text[1]} />
          </CaretContainer>
        )}
        {searchable && (
          <Primitives.Box position="absolute" left={0} top={0} width={1} px={0}>
            <Input
              autocomplete="chrome-off"
              autoFocus={true}
              border={border}
              borderColor={
                selectedOptions.length > 0 ? colors.text[2] : borderColor
              }
              blurHandler={blurHandler}
              color={colors.text[0]}
              focusHandler={focusHandler}
              changeHandler={e => changeHandler(e)}
              fontSize="0.86em"
              placeholder={placeholder}
              value={defaultValue ? defaultValue : search}
              px="1em"
              py="4px"
              width="calc(100% - 16px)"
            />
          </Primitives.Box>
        )}
      </StyledSelect>

      {collapsed && (
        <ListDropdown
          className={className}
          enableLazyLoading={enableLazyLoading}
          hoverweight={2}
          listMaxHeight={listMaxHeight}
          listMaxWidth={listMaxWidth}
          options={options}
          search={searchable ? search : null}
          selectHandler={selectHandler}
          selectType={selectType}
          shadowRequired={shadowRequired}
          px={12}
          minWidth={listWidth}
          width="auto"
        />
      )}
    </Primitives.Box>
  );
};

Dropdown.propTypes = propTypes;
Dropdown.defaultProps = defaultProps;
export default Dropdown;
