import React from "react";
import Primitives from "../primitives";
import Check from "../glyphs/elements/check";
import { bool, func, oneOfType, number } from "prop-types";
import { colors } from "../utils/theme/index";
import styled from "styled-components";

const propTypes = {
  checked: oneOfType([bool, number]),
  clickHandler: func
};

export const CheckBoxContainer = styled(Primitives.Box)`
  &:hover {
    border-color: ${colors.primary[3]};
  }
`;

const Checkbox = ({ checked, clickHandler }) => (
  <CheckBoxContainer
    background={checked ? colors.primary[3] : "white"}
    border="1px solid"
    borderColor={checked ? colors.primary[3] : colors.text[2]}
    borderRadius={4}
    height={18}
    width={18}
    onClick={clickHandler}
  >
    {checked && (
      <Primitives.Flex pb="4px" pl="4px" pr="4px" pt="4px">
        <Check fill="white" />
      </Primitives.Flex>
    )}
  </CheckBoxContainer>
);
Checkbox.propTypes = propTypes;
export default Checkbox;
