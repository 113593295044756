import React from "react";

const Error = ({ ...props }) => (
  <svg
    width="3"
    height="17"
    viewBox="0 0 3 17"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.32829 17C2.06173 17 2.65625 16.4054 2.65625 15.6719C2.65625 14.9383 2.06173 14.3438 1.32829 14.3438H1.32763C0.594188 14.3438 0 14.9383 0 15.6719C0 16.4054 0.594852 17 1.32829 17Z"
      fill="inherit"
    />
    <path
      d="M2.65625 10.9119V1.30681C2.65625 0.58505 2.06165 0 1.32812 0C0.594595 0 0 0.58505 0 1.30681V10.9119C0 11.6337 0.594595 12.2187 1.32812 12.2187C2.06165 12.2187 2.65625 11.6337 2.65625 10.9119Z"
      fill="inherit"
    />
  </svg>
);

export default Error;
