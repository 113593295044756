import React from "react";

const calendar = ({ ...props }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    title="calendar"
    {...props}
  >
    <path
      d="M12.7502 1.87689H10.1252V0.876892C10.1252 0.808142 10.069 0.751892 10.0002 0.751892H9.12525C9.05649 0.751892 9.00025 0.808142 9.00025 0.876892V1.87689H5.00024V0.876892C5.00024 0.808142 4.94399 0.751892 4.87524 0.751892H4.00024C3.93149 0.751892 3.87524 0.808142 3.87524 0.876892V1.87689H1.25024C0.973682 1.87689 0.750244 2.10033 0.750244 2.37689V12.7519C0.750244 13.0285 0.973682 13.2519 1.25024 13.2519H12.7502C13.0268 13.2519 13.2502 13.0285 13.2502 12.7519V2.37689C13.2502 2.10033 13.0268 1.87689 12.7502 1.87689ZM12.1252 12.1269H1.87524V6.18939H12.1252V12.1269ZM1.87524 5.12689V3.00189H3.87524V3.75189C3.87524 3.82064 3.93149 3.87689 4.00024 3.87689H4.87524C4.94399 3.87689 5.00024 3.82064 5.00024 3.75189V3.00189H9.00025V3.75189C9.00025 3.82064 9.05649 3.87689 9.12525 3.87689H10.0002C10.069 3.87689 10.1252 3.82064 10.1252 3.75189V3.00189H12.1252V5.12689H1.87524Z"
      fill="black"
      fillOpacity="0.25"
    />
  </svg>
);

export default calendar;
