import styled from "styled-components"

import {shouldForwardProp} from "../shared"
import textProps from "./shared"
import {labelVariants} from "./variants"

const Label = styled.label.withConfig({
  shouldForwardProp
})`
  ${textProps};
  ${labelVariants};
`

export default Label
