import { has, reduce } from "ramda";

const styledSystemProps = [
  "alignContent",
  "alignItems",
  "alignSelf",
  "background",
  "backgroundColor",
  "backgroundImage",
  "backgroundPosition",
  "backgroundRepeat",
  "backgroundSize",
  "bg",
  "border",
  "borderBottom",
  "borderBottomColor",
  "borderBottomLeftRadius",
  "borderBottomRightRadius",
  "borderBottomStyle",
  "borderBottomWidth",
  "borderColor",
  "borderLeft",
  "borderLeftColor",
  "borderLeftStyle",
  "borderLeftWidth",
  "borderRadius",
  "borderRight",
  "borderRightColor",
  "borderRightStyle",
  "borderRightWidth",
  "borderStyle",
  "borderTop",
  "borderTopColor",
  "borderTopLeftRadius",
  "borderTopRightRadius",
  "borderTopStyle",
  "borderTopWidth",
  "borderWidth",
  "borderX",
  "borderY",
  "bottom",
  "boxShadow",
  "display",
  "flex",
  "flexBasis",
  "flexDirection",
  "flexGrow",
  "flexShrink",
  "flexWrap",
  "fontFamily",
  "fontSize",
  "fontStyle",
  "fontWeight",
  "gridArea",
  "gridAutoColumns",
  "gridAutoFlow",
  "gridAutoRows",
  "gridColumn",
  "gridColumnGap",
  "gridGap",
  "gridRow",
  "gridRowGap",
  "gridTemplateAreas",
  "gridTemplateColumns",
  "gridTemplateRows",
  "height",
  "justifyContent",
  "justifyItems",
  "justifySelf",
  "left",
  "letterSpacing",
  "lineHeight",
  "m",
  "margin",
  "marginBottom",
  "marginLeft",
  "marginRight",
  "marginTop",
  "maxHeight",
  "maxWidth",
  "mb",
  "minHeight",
  "minWidth",
  "ml",
  "mr",
  "mt",
  "mx",
  "my",
  "order",
  "overflow",
  "overflowX",
  "overflowY",
  "p",
  "padding",
  "paddingBottom",
  "paddingLeft",
  "paddingRight",
  "paddingTop",
  "pb",
  "pl",
  "position",
  "pr",
  "pt",
  "px",
  "py",
  "right",
  "size",
  "textAlign",
  "textShadow",
  "top",
  "verticalAlign",
  "width",
  "zIndex"
];

const customStyleProps = [
  "boxSizing",
  "cursor",
  "hasClickHandler",
  "filter",
  "fill",
  "isActive",
  "lineClamp",
  "listStyle",
  "textDecoration",
  "textOverflow",
  "transform",
  "transition",
  "userSelect",
  "variant",
  "whiteSpace",
  "wordBreak"
];

/*
 * Some react components forward every single prop to the dom.  If the prop
 * isn't a standard prop (like all of our style-props), it will generate an
 * error in the console.  Since v5.1.0, styled-components now provides the
 * ability to prevent specific props from being passed to the DOM.  This
 * function converts the string list of style props to a map for O(1) lookup.
 */
const excludedProps = reduce(
  (acc, current) => ({
    ...acc,
    [current]: true
  }),
  {},
  [...styledSystemProps, ...customStyleProps]
);

const shouldForwardProp = prop => !has(prop, excludedProps);

export default shouldForwardProp;
