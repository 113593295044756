import React from "react";
import { array, bool, number, object, oneOfType, string } from "prop-types";
import { Link } from "react-router-dom";
import { colors } from "../utils/theme/index";
import Primitives from "../primitives";
import styled from "styled-components";

const propTypes = {
  /** Text color of the hyper link */
  color: string,
  /** What Font family should anchor text use*/
  fontFamily: string,
  /** What font size should be used on anchor text */
  fontSize: oneOfType([array, number]),
  /** Font styling for anchor text */
  fontStyle: string,
  /** What fontweight should be applied to anchor text */
  fontWeight: oneOfType([array, bool, number, string]),
  /**Hover state color */
  hover: object,
  /** Anchor text */
  label: oneOfType([object, string]),
  /** Line height for text in anchor */
  lineHeight: number,
  /** Link for redirection  */
  link: oneOfType([object, string]),
  /** In Which window link should open */
  target: string,
  /** Textdecoration line  */
  textdecorationline: bool,
  /** Type of cta to be used */
  type: string
};

const defaultProps = {
  color: colors.primary[3],
  fontFamily: "Lato",
  fontSize: 1,
  fontStyle: "normal",
  fontWeight: 2,
  lineHeight: 1,
  link: "http://www.aknamed.com",
  target: "_self",
  textdecorationline: true,
  type: "link"
};

export const HyperLink = styled.a`
  color: inherit;
  &:link,
  &:visited {
    color: ${props => props.color};
  }
  &:hover,
  &:active {
    text-decoration-line: ${props =>
      props.hover && props.hover.textDecorationLine && `underline`};
    color: ${props => props.hover && props.hover.color};
  }
  text-decoration: ${props => !props.textDecorationLine && `none`};
`;
export const StyledLink = styled(Link)`
  color: inherit;
  &:link,
  &:visited {
    color: ${props => props.color};
  }
  &:hover,
  &:active {
    text-decoration-line: ${props =>
      props.hover.textDecorationLine && `underline`};
    color: ${props => props.hover.color};
  }
  text-decoration: ${props => !props.textdecorationline && `none`};
`;

const Cta = ({
  color,
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  hover,
  label,
  lineHeight,
  link,
  target,
  type,
  textdecorationline
}) =>
  type === "link" ? (
    <StyledLink
      to={link}
      hover={hover}
      target={target}
      textdecorationline={textdecorationline ? 1 : 0}
    >
      <Primitives.Text
        color={color}
        fontFamily={fontFamily}
        fontSize={fontSize}
        fontStyle={fontStyle}
        fontWeight={fontWeight}
        lineHeight={lineHeight}
      >
        {label}
      </Primitives.Text>
    </StyledLink>
  ) : (
    <HyperLink
      href={link}
      hover={hover}
      target={target}
      textdecorationline={textdecorationline ? 1 : 0}
    >
      <Primitives.Text
        color={color}
        fontFamily={fontFamily}
        fontSize={fontSize}
        fontStyle={fontStyle}
        fontWeight={fontWeight}
        lineHeight={lineHeight}
      >
        {label}
      </Primitives.Text>
    </HyperLink>
  );

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
