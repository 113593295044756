import React from "react";

const Close = ({ ...props }) => (
  <svg
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.46603 8.00012L15.6962 1.76972C16.1017 1.36446 16.1017 0.709208 15.6962 0.303946C15.291 -0.101315 14.6357 -0.101315 14.2305 0.303946L8.00007 6.53434L1.76986 0.303946C1.36441 -0.101315 0.70935 -0.101315 0.304088 0.303946C-0.101363 0.709208 -0.101363 1.36446 0.304088 1.76972L6.5343 8.00012L0.304088 14.2305C-0.101363 14.6358 -0.101363 15.291 0.304088 15.6963C0.506055 15.8984 0.77161 16 1.03698 16C1.30234 16 1.56771 15.8984 1.76986 15.6963L8.00007 9.46589L14.2305 15.6963C14.4326 15.8984 14.698 16 14.9634 16C15.2287 16 15.4941 15.8984 15.6962 15.6963C16.1017 15.291 16.1017 14.6358 15.6962 14.2305L9.46603 8.00012Z"
      fill="inherit"
    />
  </svg>
);

export default Close;
