import React from "react";

const Caret = ({ ...props }) => (
  <svg viewBox="0 0 12 8" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.862 0.104492L11.8048 1.06972L6.00005 7.01251L0.195312 1.06972L1.13812 0.104492L6.00005 5.08205L10.862 0.104492Z"
      fill="inherit"
    />
  </svg>
);

export default Caret;
