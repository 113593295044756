import React from "react";

const Circle = ({ ...props }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="11" cy="11" r="11" fill="inherit" />
  </svg>
);

export default Circle;
