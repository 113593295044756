import React from "react";
import { number } from "prop-types";
import Circle from "../glyphs/elements/circle";
import NewItem from "../glyphs/elements/new-item";
import { colors } from "../utils/theme";

const propTypes = {
  /** breakpoint is added to make design responsive */
  breakPoint: number
};
const defaultProps = {
  breakPoint: 610
};
const designs = {
  desktop: <NewItem fill={colors["highlight-blue"]} />,
  mobile: <Circle fill={colors["greenish-blue"]} height="5px" width="5px" />
};

const NewItems = ({ breakPoint }) =>
  window.innerWidth < breakPoint ? designs["mobile"] : designs["desktop"];

NewItems.propTypes = propTypes;
NewItems.defaultProps = defaultProps;
export default NewItems;
