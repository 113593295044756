import React from "react";

const DownwardArrow = ({ ...props }) => (
  <svg
    width="10"
    height="13"
    viewBox="0 0 10 13"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.90048 12.4168C5.13482 12.6518 5.51476 12.6531 5.74908 12.4195L9.56763 8.61408C9.80195 8.38056 9.80193 8.0007 9.56758 7.76564C9.33324 7.53058 8.9533 7.52933 8.71898 7.76285L5.32472 11.1455L1.93012 7.74056C1.69577 7.5055 1.31584 7.50426 1.08151 7.73778C0.84719 7.9713 0.847209 8.35116 1.08156 8.58622L4.90048 12.4168ZM4.72416 0.99803L4.7247 11.992L5.92481 11.9959L5.92427 1.00197L4.72416 0.99803Z"
      fill="inherit"
    />
  </svg>
);

export default DownwardArrow;
