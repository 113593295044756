import React from "react";

const ForwardAngular = ({ ...props }) => (
  <svg
    width="7"
    height="12"
    viewBox="0 0 7 12"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.471404 1.80474C0.211054 1.54439 0.211055 1.12228 0.471404 0.861929C0.731754 0.601579 1.15386 0.601579 1.41421 0.861928L6.74755 6.19526L1.41421 11.5286C1.15386 11.7889 0.731754 11.7889 0.471405 11.5286C0.211055 11.2682 0.211055 10.8461 0.471404 10.5858L4.86193 6.19526L0.471404 1.80474Z"
      fill="inherit"
    />
  </svg>
);

export default ForwardAngular;
