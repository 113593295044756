import {reduce} from "ramda"

const DEFAULT_TRUNCATE_SYMBOL = `....`
const EXCLUDE_TAGS = [`img`, `br`] // non-closed tags
const KEY_VALUE_REGEX = `([\\w|-]+\\s*(=\\s*"[^"]*")?\\s*)*`
const IS_CLOSE_REGEX = `\\s*\\/?\\s*`
const CLOSE_REGEX = `\\s*\\/\\s*`
const SELF_CLOSE_REGEX = new RegExp(
  `<\\/?\\w+\\s*${KEY_VALUE_REGEX}${CLOSE_REGEX}>`
)
const HTML_TAG_REGEX = new RegExp(
  `<\\/?\\w+\\s*${KEY_VALUE_REGEX}${IS_CLOSE_REGEX}>`
)
const URL_REGEX = /(((ftp|https?):\/\/)[-\w@:%_+.~#?,&//=]+)|((mailto:)?[_.\w-]+@([\w][\w-]+\.)+[a-zA-Z]{2,3})/g

export const SAMPLE_RAW_DATA = `<p id="content">
    <span lang="EN">While the first phase of 5G NR focuses on bringing new mobile broadband experiences, the broader ambition of 5G is to expand cellular technology into a multitude of industries, transforming and creating new ones along the way.
      <a href="/news/onq/2018/02/14/driving-5g-nr-technology-roadmap-further-expand-5g-ecosystem">Defining 5G NR for new vertical services</a>,
      such as the industrial IoT, will become the focus for 3GPP Release 16 and beyond, and new projects were
      <a href="/news/onq/2018/06/14/live-san-diego-3gpp-sets-out-expand-5g-new-industries-video">agreed upon at the 3GPP June Plenary meetings</a>
      in San Diego.
    </span>
    <br /><br />
    <span lang="EN">While the first phase of 5G NR focuses on bringing new mobile broadband experiences, the broader ambition of 5G is to expand cellular technology into a multitude of industries, transforming and creating new ones along the way.
      <a href="/news/onq/2018/02/14/driving-5g-nr-technology-roadmap-further-expand-5g-ecosystem">Defining 5G NR for new vertical services</a>,
      such as the industrial IoT, will become the focus for 3GPP Release 16 and beyond, and new projects were
      <a href="/news/onq/2018/06/14/live-san-diego-3gpp-sets-out-expand-5g-new-industries-video">agreed upon at the 3GPP June Plenary meetings</a>
      in San Diego.
    </span>
  </p>`

const dumpCloseTag = tags =>
  reduce(
    (html, tag) => {
      let reduceHtml = html
      if (EXCLUDE_TAGS.indexOf(tag) === -1) {
        reduceHtml += `</${tag}>`
      }
      return reduceHtml
    },
    ``,
    tags.reverse()
  )

const getTag = str => {
  let tail = str.indexOf(` `)
  if (tail === -1) {
    tail = str.indexOf(`>`)
    if (tail === -1) {
      throw new Error(`HTML tag is not well-formed: ${str}`)
    }
  }
  return str.substring(1, tail)
}

export const getInnerTextOfHtml = text =>
  text.slice().replace(/<(?:.|\n)*?>/g, "")

export const truncateHtml = (string, maxLength) => {
  const items = [] // stack for saving tags
  let total = 0 // chars so far
  let content = `` // truncated text storage
  let matches = true

  let result = null
  let index = null
  let tag = null
  let selfClose = null
  let htmlString = string

  while (matches) {
    matches = HTML_TAG_REGEX.exec(htmlString)

    if (!matches) {
      if (total >= maxLength) {
        break
      }

      matches = URL_REGEX.exec(htmlString)
      if (!matches || matches.index >= maxLength) {
        content += htmlString.substring(0, maxLength - total)
        break
      }

      while (matches) {
        // eslint-disable-next-line prefer-destructuring
        result = matches[0]
        // eslint-disable-next-line prefer-destructuring
        index = matches.index
        content += htmlString.substring(0, index + result.length - total)
        htmlString = htmlString.substring(index + result.length)
        matches = URL_REGEX.exec(htmlString)
      }
      break
    }

    // eslint-disable-next-line prefer-destructuring
    result = matches[0]
    // eslint-disable-next-line prefer-destructuring
    index = matches.index

    if (total + index > maxLength) {
      // exceed maxLength - dump everything to clear stack
      content += htmlString.substring(0, maxLength - total)
      break
    } else {
      total += index
      content += htmlString.substring(0, index)
    }

    if (result[1] === `/`) {
      // move out open tag
      items.pop()
      selfClose = null
    } else {
      selfClose = SELF_CLOSE_REGEX.exec(result)
      if (!selfClose) {
        tag = getTag(result)
        items.push(tag)
      }
    }

    if (selfClose) {
      content += selfClose[0]
    } else {
      content += result
    }
    htmlString = htmlString.substring(index + result.length)
  }

  if (htmlString.length > maxLength - total) {
    content += DEFAULT_TRUNCATE_SYMBOL
  }

  content += dumpCloseTag(items)
  return content
}
