import styled from "styled-components";

import { defaultSystemProps, shouldForwardProp } from "./shared";

const Flex = styled.div.withConfig({
  shouldForwardProp
})`
  background-position: center right;
  display: flex;
  ${defaultSystemProps};
`;

Flex.displayName = "Primitives.Flex";

export default Flex;
