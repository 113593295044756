import React, { useMemo } from "react";
import { node, bool } from "prop-types";
import { ThemeProvider } from "styled-components";
import { forEachObjIndexed } from "ramda";

export const colors = {
  atomic: "#444B4F",
  "athens-gray": "#EAEBF0",
  "background-blue": "#F9FAFF",
  "background-dark-yellow": "rgba(255, 188, 31, 0.1)",
  "background-dull-red": "rgba(253, 220, 209, 0.5)",
  "background-light-red": "#F5DCDC",
  "background-light-blue": "#F2F4FF",
  "background-dark-green": "#219653",
  "background-error": "#F34040",
  "background-caution": "#F7F2C6",
  "background-green": "#27AE60",
  "background-light": "#F2F4F7",
  "background-hint-green": "#EAFFF3",
  "background-transparent-red": "rgba(255, 187, 187, 0.3)",
  "background-yellow": "#F37D10",
  "cardin-green": "#415C4D",
  "dull-green": "#A6DDBD",
  "dull-orange": "rgba(245, 129, 88, 0.5)",
  "dusty-grey": "#979797",
  "dark-purple": "#8A44FF",
  "dark-yellow": "#FFAE10",
  "dark-gold": "#B2A000",
  "highlight-dark": "#3A4667",
  "light-blue": "#F4F6FF",
  "light-green": "#2EE09D",
  "light-pink": "#FFB5EA",
  "highlight-blue": "#194DDD",
  "oxford-blue": "#202A39",
  "greenish-blue": "#257383",
  "ghost-white": "#F2F4FD",
  "sky-blue": "#40B6FF",
  purple: "#F7F8FC",
  whitesmoke: "#F5F5F5",
  error: ["#FB4E4E", "#F03E41", "#FF6262"],
  text: ["#1E2B51", "#7A8A9C", "#BEC8D2", "#E8ECEF"],
  white: "#FFFFFF",
  warning: ["#F6A609", "#E89806", "#FFBC1F", "#DD9F26"],
  accent: ["#F9F9FA", "#CAD1EF", "#839BE2", "#5C6FCE", "#2851C8", "#17213E"],
  "pattens-blue": "#D6DCE1",
  primary: [
    "#FDDCD1",
    "#FFBCA5",
    "#FCA080",
    "#F58158",
    "#F77548",
    "#E86739",
    "#D34E1F"
  ],
  "resolution-blue": "#A3AAC7",
  "shadow-grey": "rgba(116, 116, 116, 0.3)",
  success: ["#2AC769", "#57AD68", "#40DD7F"]
};

export const breakPoint = 550;

export const colorRoles = {
  background: "white"
};

export const themeColors = {
  colorRoles,
  colors
};

export const themeSettings = {
  breakpointInts: [
    550, // 0
    950, // 1
    1200, // 2
    1440, // 3
    1800 // 4
  ],
  breakpoints: ["550px", "950px", "1200px", "1440px", "1800px"],
  fontSizes: [
    10, // 0
    12, // 1
    14, // 2
    16, // 3
    18, // 4
    20, // 5
    24, // 6
    28, // 7
    30, //8
    32, // 9
    36, // 10
    40, // 11
    44, // 12
    48, // 13
    52 // 14
  ],
  fontWeights: [200, 400, 600, 900],
  // Values for Grid used by GridSystem components
  grid: {
    columns: [6, 8, 12, 12, 12, 12],
    gutters: ["4px", "8px", "12px", "12px", "12px", "16px"],
    margins: [3, 5, 5, 5, 7],
    maxWidth: 1420
  },
  lineHeights: [
    1, //0
    1.125, //1
    1.25, //2
    1.5, //3
    3 //4
  ],
  shadows: [
    "none",
    "0 2px 4px 0 rgba(74,90,117,0.16)",
    "0 4px 16px 0 rgba(74,90,117,0.08)",
    "0px 4px 32px 0px rgba(74,90,117,0.24)",
    "0 12px 16px -8px rgba(74,90,117,0.26)",
    "0 15px 13px -10px rgba(0,0,0,0.4)",
    "0 16px 15px -11px rgba(0,0,0,0.4)",
    "0 27px 22px -18px rgba(0,0,0,0.4)",
    "0 35px 28px -23px rgba(0,0,0,0.4)",
    "0px 4px 20px rgba(149, 162, 233, 0.3)"
  ],
  space: [
    0, // 0
    8, // 1
    16, // 2
    24, // 3
    32, // 4
    48, // 5
    96, // 6
    130 // 7
  ],
  spacings: [0, 8, 16, 24, 32, 48, 96, 130],
  unitPadding: [24, 48, 48, 48, 48, 48]
};

const propTypes = {
  children: node,
  hasColors: bool
};

const mergedColors = () => {
  const cols = { ...colors };
  forEachObjIndexed((value, key) => {
    cols[key] = cols[value];
  }, colorRoles);
  return cols;
};

const Theme = ({ hasColors = true, children }) => {
  const themeOptions = useMemo(
    () =>
      hasColors
        ? { ...themeColors, colors: { ...mergedColors() }, ...themeSettings }
        : themeSettings,
    [hasColors]
  );

  return <ThemeProvider theme={themeOptions}>{children}</ThemeProvider>;
};

Theme.propTypes = propTypes;

export default Theme;
