import styled from "styled-components"

import {shouldForwardProp} from "../shared"
import textProps from "./shared"

const H2 = styled.h2.withConfig({
  shouldForwardProp
})`
  ${textProps};
`

export default H2
