import React from "react";
import NoResultsIcon from "../glyphs/elements/no-results";
import Primitives from "../primitives";
import { colors } from "../utils/theme";
import { bool } from "prop-types";

const propTypes = {
  /** when small "no result icon" is required */
  small: bool
};

const defaultProps = {
  small: false
};

const NoResults = ({ small }) => (
  <Primitives.Flex alignItems="center" flexDirection="column">
    <NoResultsIcon
      {...(small && {
        width: 63,
        height: 61
      })}
    />
    <Primitives.Text
      color={colors.text[0]}
      fontSize={small ? 3 : 5}
      fontWeight={3}
      lineHeight={4}
    >
      Sorry, No Results Found
    </Primitives.Text>
    {!small && (
      <Primitives.Text color={colors.text[1]} fontSize={3} lineHeight={3}>
        There weren&apos;t any data/results for the selected hospital centres or
        date range
      </Primitives.Text>
    )}
  </Primitives.Flex>
);
NoResults.propTypes = propTypes;
NoResults.defaultProps = defaultProps;
export default NoResults;
