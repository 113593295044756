import React from "react";

const Back = ({ ...props }) => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    title="back-icon"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.41424 10.0001L10.7071 16.293L9.29292 17.7072L0.585815 9.00008L9.29292 0.292969L10.7071 1.70718L4.41424 8.00008H19V10.0001H4.41424Z"
      fill="inherit"
    />
  </svg>
);
export default Back;
