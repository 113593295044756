import styled from "styled-components";

import { defaultSystemProps, shouldForwardProp } from "./shared";

const Box = styled.div.withConfig({
  shouldForwardProp
})`
  background-position: center right;
  ${defaultSystemProps};
`;

Box.displayName = "Primitives.Box";

export default Box;
