import { elementType } from "prop-types";
import styled, { css } from "styled-components";
import { stylePropType } from "../utils";
import { defaultSystemProps, shouldForwardProp } from "./shared";

const buttonReset = css`
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0;
  color: inherit;
  border: none;
  font: inherit;
  line-height: normal;
  overflow: visible;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  outline: none;
  box-shadow: none;
  &:hover {
    background: ${props => !props.disabled && props.hover.backgroundColor};
    color: ${props => !props.disabled && props.hover.color};
    border-color: ${props => !props.disabled && props.hover.borderColor};
    cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  }
  &:focus {
    background: ${props => props.active.backgroundColor};
    color: ${props => props.active.color};
    border-color: ${props => props.hover.borderColor};
  }
  & svg {
    stroke: ${props => props.color};
  }
  &:hover svg {
    fill: ${props => props.hover.fill && props.hover.fill};
    stroke: ${props => props.hover.stroke && props.hover.stroke};
  }
  &:focus svg {
    stroke: ${props => props.active.color};
  }
`;

const Button = styled.button.withConfig({
  shouldForwardProp
})`
  ${buttonReset};
  ${defaultSystemProps};
`;

const propTypes = {
  // Font size css style system prop
  fontSize: stylePropType,
  // Polymorphic prop that determines what's ultimately being rendered
  tag: elementType
};

const defaultProps = {
  active: { backgroundColor: "inherit", color: "inherit" },
  hover: {
    backgroundColor: "inherit",
    color: "inherit",
    borderColor: "inherit"
  }
};

Button.defaultProps = defaultProps;
Button.propTypes = propTypes;
Button.displayName = "Primitives.Button";

export default Button;
