import React from "react";
const Check = ({ ...props }) => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.4141 4.24264L5.65674 8.48528L4.24252 9.89949L-0.000115871 5.65685L1.4141 4.24264Z"
      fill="inherit"
    />
    <path
      d="M8.48517 2.82843L9.89938 4.24264L4.24252 9.89949L2.82831 8.48528L8.48517 2.82843Z"
      fill="inherit"
    />
  </svg>
);

export default Check;
