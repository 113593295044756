import indianNumberFormat from "./indianNumberFormat";
import roundOffTwoDecimal from "./round-off-two-decimal";

const ConvertMoneyToLakhsCrores = value => {
  var val = Math.abs(value);
  if (val >= 10000000) {
    val = indianNumberFormat(roundOffTwoDecimal(val / 10000000)) + " Cr";
  } else if (val >= 100000) {
    val = indianNumberFormat(roundOffTwoDecimal(val / 100000)) + " Lakhs";
  } else if (val >= 1000) {
    val = roundOffTwoDecimal(val / 1000) + " K";
  } else val = indianNumberFormat(roundOffTwoDecimal(val));
  return val;
};

export default ConvertMoneyToLakhsCrores;
