import React, { useState, useRef, useEffect } from "react";
import { array, bool, number, node, oneOfType, string } from "prop-types";
import styled from "styled-components";
import ListDropdown from "../list-dropdown/index";
import Primitives from "../primitives";
import { colors } from "../utils/theme";

const propTypes = {
  /** Background color of Nav Item */
  backgroundColor: string,
  /** Border color of Nav Item */
  borderColor: string,
  /** Top border radius of Nav Item */
  borderTopRadius: oneOfType([number, string]),
  /** List of options if subnav has dropdown */
  options: array,
  /** Text color of the Nav Item  */
  color: string,
  /** cursor value of the Nav item */
  cursor: string,
  /** What Font family should Nav Item text use*/
  fontFamily: string,
  /** Font weight of text */
  fontWeight: number,
  /** line height for texts in  Nav Item  */
  height: oneOfType([string, number]),
  /**Hover color */
  hoverColor: string,
  id: oneOfType([array, string]),
  /** Nav Item text that should be visible */
  label: oneOfType([string, node]),
  /** padding bottom to be applied to Nav Item  */
  pb: oneOfType([string, number]),
  /** padding top to be applied to Nav Item  */
  pt: number,
  /** horizontal padding to be applied to navigation item*/
  px: number,
  /** selected navigation item */
  selected: bool
};

const defaultProps = {
  borderColor: "highlight-dark",
  color: colors.text[0],
  fontFamily: "Lato",
  lineHeight: 2,
  pb: 1,
  pt: 1,
  px: 0
};
export const NavContainer = styled(Primitives.Flex)`
  cursor: ${props => props.cursor || "pointer"};
  text-shadow: ${props =>
    props.selected &&
    `-0.045ex 0 ${props.hovercolor}, 0.045ex 0 ${props.hovercolor}`};
  &:hover {
    border-bottom: ${props => colors[props.colorBorder]} solid 4px;
    color: ${props => props.hoverColor};
  }
`;

const NavItem = ({
  backgroundColor,
  borderColor,
  borderTopRadius,
  color,
  cursor,
  height,
  fontFamily,
  fontWeight,
  hoverColor,
  id,
  label,
  options,
  pb,
  pt,
  px,
  selected
}) => {
  const [open, setOpen] = useState(false);
  const node = useRef();
  const handleClick = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);
  return (
    <>
      <Primitives.Flex
        onClick={() => {
          setOpen(!open);
        }}
        ref={node}
        flexDirection="column"
      >
        <NavContainer
          alignItems="center"
          backgroundColor={backgroundColor}
          borderBottom={selected ? "4px solid" : "4px solid white"}
          borderColor={selected && borderColor}
          color={selected ? hoverColor : color}
          cursor={cursor}
          display="inline-flex"
          fontFamily={fontFamily}
          fontSize={1}
          fontWeight={fontWeight ? fontWeight : 1}
          hovercolor={hoverColor}
          height={height}
          letterSpacing={!selected && "-0.02px"}
          pb={pb}
          pt={pt}
          px={px}
          selected={selected}
          borderTopLeftRadius={borderTopRadius}
          borderTopRightRadius={borderTopRadius}
        >
          {label}
        </NavContainer>
        {open && options && (
          <Primitives.Flex position="relative">
            <ListDropdown
              activeIndex={id}
              activeColor={colors["background-light-blue"]}
              color={colors.text[0]}
              fontSize={1}
              fontWeight={1}
              listMaxHeight="86vh"
              options={options}
              top="3.5px"
              width="240px"
              selectHandler={() => {}}
            />
          </Primitives.Flex>
        )}
      </Primitives.Flex>
    </>
  );
};

NavItem.propTypes = propTypes;
NavItem.defaultProps = defaultProps;
export default NavItem;
