import React from "react";
import { string, number, node, oneOfType } from "prop-types";
import Primitives from "../primitives";
import styled from "styled-components";

const propTypes = {
  /** Background color of alert */
  backgroundColor: string,
  /** Border around alert */
  border: string,
  /** Color of border around alert */
  borderColor: string,
  /** Border radius around alert */
  borderRadius: number,
  /** Text color of alert */
  color: string,
  /** Flex direction */
  flexDirection: string,
  /** Font family of text of alert */
  fontFamily: string,
  /** Font size of text of alert */
  fontSize: number,
  /** Font style of text of alert */
  fontStyle: string,
  /** Font weight of text of alert */
  fontWeight: number,
  /** Glyph along with alert message */
  glyph: node,
  /** Alert message */
  label: node,
  /** Lineheight of text of alert */
  lineHeight: number,
  /**Horizontal Margin for glyph */
  mx: number,
  /** Padding left for alert  */
  pl: number,
  /** Padding right for alert */
  pr: number,
  /** Vertical padding of text of alert */
  py: oneOfType([number, string])
};
const defaultProps = {
  border: "1px solid",
  borderRadius: 8,
  fontFamily: "Lato",
  fontSize: 2,
  fontStyle: "normal",
  fontWeight: 0,
  lineHeight: 2.15,
  pl: 18,
  pr: 3,
  py: 2
};
export const AlertContainer = styled(Primitives.Flex)`
  overflow-wrap: anywhere;
`;
const Alert = ({
  backgroundColor,
  border,
  borderColor,
  borderRadius,
  color,
  flexDirection,
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  glyph,
  label,
  lineHeight,
  mx,
  pl,
  pr,
  py
}) => (
  <AlertContainer
    backgroundColor={backgroundColor}
    border={border}
    borderColor={borderColor}
    borderRadius={borderRadius}
    color={color}
    flexDirection={flexDirection}
    fontFamily={fontFamily}
    fontSize={fontSize}
    fontStyle={fontStyle}
    fontWeight={fontWeight}
    lineHeight={lineHeight}
    pl={pl}
    pr={pr}
    py={py}
  >
    {glyph && (
      <Primitives.Flex alignItems="center" display="inline-flex" mx={mx}>
        {glyph}
      </Primitives.Flex>
    )}
    {label}
  </AlertContainer>
);

Alert.propTypes = propTypes;
Alert.defaultProps = defaultProps;
export default Alert;
