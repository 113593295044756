import React from "react";
import {
  array,
  bool,
  func,
  number,
  object,
  node,
  oneOfType,
  string
} from "prop-types";
import Caret from "../glyphs/elements/caret";
import { colors } from "../utils/theme";
import Primitives from "../primitives";

const propTypes = {
  /** Align items according to prop */
  alignItems: string,
  /**active state color */
  active: object,
  /** What background color to use*/
  backgroundColor: string,
  /** Border around the button */
  border: oneOfType([number, string]),
  /** Border color */
  borderColor: string,
  /** Border radius for button */
  borderRadius: oneOfType([string, number]),
  /** Bottom left border radius of input box */
  borderBottomLeftRadius: number,
  /** Bottom right border radius of input box */
  borderBottomRightRadius: number,
  /** Right border of input box */
  borderRight: number,
  /** Top left border radius of input box */
  borderTopLeftRadius: number,
  /** Top right border radius of input box */
  borderTopRightRadius: number,
  /** Handler function for button */
  clickHandler: func,
  /** Text color of the button */
  color: string,
  /** disabled button */
  disabled: bool,
  /** What Font family should button text use*/
  fontFamily: string,
  /** What font size should be used on button text */
  fontSize: number,
  /** Font styling for button text */
  fontStyle: string,
  /** What fontweight should be applied to button text */
  fontWeight: oneOfType([number, string]),
  /** If button is iconbutton then icon path is recieved */
  glyph: node,
  /** What height a button should have */
  height: string,
  /**hover state color */
  hover: object,
  /** If the button is of dropdown type */
  isDropdown: bool,
  /** Horizontal alignment of content inside button */
  justifyContent: string,
  /** How much to be given between the letters */
  letterSpacing: number,
  /** Button text that should be visible */
  label: oneOfType([object, string]),
  /** line height for texts in button */
  lineHeight: oneOfType([string, number]),
  /** horizontal padding */
  px: oneOfType([string, number, array]),
  /** vertical padding */
  py: oneOfType([string, number, array]),
  /** Width of button  */
  width: oneOfType([string, number])
};

const defaultProps = {
  active: {
    backgroundColor: "inherit",
    color: "inherit",
    borderColor: "inherit"
  },
  backgroundColor: "white",
  borderRadius: 4,
  fontFamily: "Lato",
  fontSize: 1,
  fontStyle: "normal",
  fontWeight: 1,
  hover: { backgroundColor: "inherit", color: "inherit" },
  letterSpacing: 0.648
};

const Button = ({
  active,
  alignItems,
  backgroundColor,
  border,
  borderColor,
  borderRadius,
  borderBottomLeftRadius,
  borderBottomRightRadius,
  borderTopLeftRadius,
  borderTopRightRadius,
  clickHandler,
  color,
  disabled,
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  glyph,
  hover,
  isDropdown,
  justifyContent,
  label,
  letterSpacing,
  lineHeight,
  px,
  py,
  width
}) => (
  <Primitives.Button
    active={active}
    alignItems={alignItems}
    backgroundColor={backgroundColor}
    border={border}
    borderRadius={borderRadius}
    borderBottomLeftRadius={borderBottomLeftRadius}
    borderBottomRightRadius={borderBottomRightRadius}
    borderTopLeftRadius={borderTopLeftRadius}
    borderTopRightRadius={borderTopRightRadius}
    borderColor={borderColor}
    color={color}
    display="flex"
    disabled={disabled}
    fontFamily={fontFamily}
    fontStyle={fontStyle}
    fontSize={fontSize}
    fontWeight={fontWeight}
    hover={hover}
    justifyContent={justifyContent}
    lineHeight={lineHeight}
    letterSpacing={letterSpacing}
    onClick={clickHandler}
    px={px}
    py={py}
    width={width}
  >
    {glyph}
    {label && (
      <Primitives.Text
        borderColor={borderColor}
        borderRight={isDropdown && "1px solid"}
        ml={glyph && "4.37px"}
        mr={isDropdown && 12}
        pr={isDropdown && 1}
      >
        {label}
      </Primitives.Text>
    )}
    {isDropdown && <Caret fill={colors.text[1]} height={7} width={12} />}
  </Primitives.Button>
);

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;
export default Button;
