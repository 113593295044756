import React from "react";
import Primitives from "../primitives";
import { number, object, oneOfType, string, node } from "prop-types";
import styled from "styled-components";

const propTypes = {
  backgroundColor: string,
  borderRadius: oneOfType([number, string]),
  boxShadow: oneOfType([string, number]),
  color: string,
  children: node,
  fontWeight: oneOfType([number, string]),
  fontSize: oneOfType([number, string]),
  left: oneOfType([number, string]),
  padding: oneOfType([number, string]),
  toolTipTriangle: object,
  top: oneOfType([number, string]),
  width: oneOfType([number, string]),
  translate: oneOfType([number, string])
};
const defaultProps = {
  backgroundColor: "black",
  borderRadius: 4,
  color: "white",
  fontSize: 0,
  fontWeight: 1,
  left: "50%",
  padding: "6px",
  toolTipTriangle: {
    left: "50%"
  },
  top: 30,
  width: 100,
  translate: "-50%"
};

export const ToolTipContainer = styled(Primitives.Flex)`
  &:before {
    border: solid transparent;
    bottom: 100%;
    border-bottom-color: ${props => props.backgroundColor || "black"};
    border-width: 6px;
    content: " ";
    height: 0;
    left: ${props => props.tooltiptriangle.left};
    margin-left: calc(6px * -1);
    position: absolute;
    pointer-events: none;
    right: ${props => props.tooltiptriangle.right};
    top: ${props => props.tooltiptriangle.top};
    transform: ${props => props.tooltiptriangle.transform};
    width: 0;
  }
`;

const ToolTipComponent = ({
  backgroundColor,
  borderRadius,
  boxShadow,
  color,
  children,
  fontWeight,
  fontSize,
  left,
  padding,
  toolTipTriangle,
  top,
  width,
  translate
}) => (
  <ToolTipContainer
    backgroundColor={backgroundColor}
    borderRadius={borderRadius}
    boxShadow={boxShadow}
    color={color}
    fontWeight={fontWeight}
    fontSize={fontSize}
    height="auto"
    left={left}
    padding={padding}
    position="absolute"
    tooltiptriangle={toolTipTriangle}
    top={top}
    transform={`translateX(${translate})`}
    width={width}
    zIndex={100}
  >
    {children}
  </ToolTipContainer>
);

ToolTipComponent.propTypes = propTypes;
ToolTipComponent.defaultProps = defaultProps;
export default ToolTipComponent;
