import {array, number, oneOfType, shape, string} from "prop-types"

export default shape({
  color: oneOfType([array, string]),
  fontSize: oneOfType([array, number, string]),
  fontWeight: oneOfType([array, number, string]),
  justifyContent: oneOfType([array, string]),
  letterSpacing: oneOfType([array, string]),
  lineHeight: oneOfType([array, number, string]),
  mb: oneOfType([array, number, string]),
  textAlign: oneOfType([array, string])
})
