const formatDate = date => {
  let formattedMonth = new Intl.DateTimeFormat("en", { month: "short" }).format(
    date
  );
  let formattedDate = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
    date
  );
  var strDate = formattedDate + " " + formattedMonth;
  return strDate;
};
export default formatDate;
