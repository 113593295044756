import FileSaver from "file-saver";
import formatAMPM from "./formatAMPM";
import formatDate from "./formatDate";
import "regenerator-runtime/runtime";

const downloadHandlerFn = async (
  downloadLink,
  setIsDownload,
  format = "xlsx"
) => {
  setIsDownload(true);
  try {
    await fetch(downloadLink, {
      headers: {
        "content-type": "text/xlsx",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(response => response.blob())
      .then(data => {
        FileSaver.saveAs(
          data,
          `${formatDate(new Date()) + "-" + formatAMPM(new Date())}.${format}`
        );
        setIsDownload(false);
      });
  } catch (error) {
    console.log(error);
  }
};

export default downloadHandlerFn;
