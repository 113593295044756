import React from "react";
import { Bar } from "react-chartjs-2";
import {
  array,
  bool,
  func,
  object,
  oneOfType,
  number,
  string
} from "prop-types";

const propTypes = {
  /** width of bars can be reduced by barPercentage, by default bars take full width */
  barPercentage: number,
  /** Tool tip call back function */
  callback: oneOfType([func, object]),
  /** height of bbar chart */
  height: number,
  /** id of bbar chart */
  id: string,
  /** x axis labels */
  labels: array,
  /** layout values for Bar chart */
  layout: object,
  /** legend values for Bar chart */
  legend: oneOfType([bool, object]),
  /** maximum values in the y axis */
  max: number,
  /** on lcik function over clicking on graphs*/
  onClick: func,
  /** indicates whether the chart is stacked or not */
  stacked: bool,
  /** custom funciton to change the tooltip data */
  toolTipTitle: func,
  /** indicates tooltip required or not */
  tooltip: object,
  /** array of object containing color, data and label for bars */
  values: oneOfType([array, bool]),
  /** custom x axis value object */
  xAxes: object,
  /** custom y axis value object */
  yAxes: object,
  /** set index */
  setXAxis: func
};

const defaultProps = {
  callback: {
    labelColor: () => ({
      backgroundColor: "inherit"
    }),
    labelTextColor: () => {}
  },
  height: 1100,
  stacked: false,
  legend: {
    display: false,
    onClick: e => e.stopPropagation()
  },
  layout: { padding: 0 },
  tickColor: "rgba(0,0,0,0.5)",
  tooltip: {
    enabled: true,
    backgroundColor: "#000000bd",
    borderWidth: 0,
    bodyFontColor: "white",
    titleFontColor: "white",
    titleMarginBottom: 10,
    mode: "nearest",
    position: "average"
  },
  xAxes: { barPercentage: 0.5, labels: true, drawBorder: true },
  yAxes: { gridLines: true, labels: true, padding: 0, drawBorder: true }
};

const BarChart = ({
  callback,
  height,
  id,
  labels,
  layout,
  legend,
  max,
  stacked,
  tooltip,
  values,
  xAxes,
  yAxes,
  setXAxis
}) => {
  // adding the barPercentage property on each dataset because xAxes.barPercentage is deprecated
  values &&
    values.forEach(item => {
      item.barPercentage = xAxes.barPercentage;
      if (!item.label) {
        item.label = 1; // used for adding label property, react-chartjs uses this property
      }
    });
  const data = {
    labels: labels,
    datasets: values
  };
  const options = {
    legend: legend,
    layout: layout,
    borderThickness: 1,
    maintainAspectRatio: false,
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const clickedDataIndex = elements[0]._index;
        const xValue = data.labels[clickedDataIndex];
        setXAxis && setXAxis({ branch_name: xValue });
      }
    },
    responsive: true,
    tooltips: {
      backgroundColor: tooltip.backgroundColor,
      bodyFontColor: tooltip.bodyFontColor,
      enabled: tooltip.enabled,
      callbacks: callback,
      bodyFontSize: tooltip.bodyFontSize,
      titleFontColor: tooltip.titleFontColor,
      titleFontSize: tooltip.titleFontSize,
      titleMarginBottom: tooltip.titleMarginBottom,
      borderColor: "#EAEBF0",
      borderWidth: tooltip.borderWidth,
      intersect: false,
      mode: tooltip.mode,
      position: tooltip.position
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            offsetGridLines: true,
            display: xAxes.gridLines,
            drawBorder: false
          },
          ticks: {
            display: xAxes.labels,
            fontColor: xAxes.fontColor,
            fontSize: xAxes.fontSize
          },
          stacked: stacked
        }
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            display: yAxes.labels,
            max: max,
            ...(yAxes.yLabel && {
              callback: yAxes.yLabel
            }),
            fontColor: yAxes.fontColor,
            padding: yAxes.padding
          },
          gridLines: {
            borderDash: yAxes.borderDash,
            display: yAxes.gridLines,
            drawBorder: false
          },
          stacked: stacked
        }
      ]
    }
  };
  return (
    <>
      <Bar id={id} data={data} options={options} height={height} />
    </>
  );
};

BarChart.propTypes = propTypes;
BarChart.defaultProps = defaultProps;
export default BarChart;
