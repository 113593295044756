import React, { useEffect, useState } from "react";
import { bool, number, object, oneOfType, string } from "prop-types";
import ToolTipIcon from "../glyphs/elements/tool-tip";
import Primitives from "../primitives";
import ToolTipComponent from "./ToolTipComponent";

const propTypes = {
  content: oneOfType([object, string]),
  contentTextAlign: string,
  contentWidth: oneOfType([number, string]),
  contentHeight: oneOfType([number, string]),
  delay: number,
  fontSize: oneOfType([number, string]),
  triggerTooltop: bool,
  height: oneOfType([number, string]),
  left: oneOfType([number, string]),
  padding: oneOfType([number, string]),
  toolTipTriangle: object,
  top: oneOfType([number, string]),
  value: oneOfType([object, string]),
  width: oneOfType([number, string])
};
const defaultProps = {
  delay: 400,
  top: 30,
  fontSize: "10px",
  height: 12,
  width: 12
};

const Tooltip = ({
  content,
  contentTextAlign,
  contentWidth,
  contentHeight,
  delay,
  fontSize,
  triggerTooltop,
  height,
  left,
  padding,
  top,
  toolTipTriangle,
  value,
  width
}) => {
  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  useEffect(() => {
    triggerTooltop && setActive(true);
  }, [triggerTooltop]);

  return (
    <Primitives.Flex
      display="inline-block"
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
      padding={padding}
      position="relative"
    >
      {value || <ToolTipIcon height={height} width={width} />}
      {active && (
        <ToolTipComponent
          left={left}
          top={top}
          toolTipTriangle={toolTipTriangle}
          width={contentWidth}
          height={contentHeight}
        >
          <Primitives.Flex fontSize={fontSize} textAlign={contentTextAlign}>
            {content}
          </Primitives.Flex>
        </ToolTipComponent>
      )}
    </Primitives.Flex>
  );
};
Tooltip.propTypes = propTypes;
Tooltip.defaultProps = defaultProps;
export default Tooltip;
