import styled from "styled-components"

import {shouldForwardProp} from "../shared"
import textProps from "./shared"

const H4 = styled.h4.withConfig({
  shouldForwardProp
})`
  ${textProps};
`

export default H4
