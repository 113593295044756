import { bool } from "prop-types";
import styled from "styled-components";

import { defaultSystemProps, shouldForwardProp } from "./shared";

const Input = styled.input.withConfig({
  shouldForwardProp
})`
  ${defaultSystemProps};
`;

const propTypes = {
  // Boolean that determines if input gets focused when the page loads
  autoFocus: bool
};

Input.propTypes = propTypes;
Input.displayName = "Primitives.Input";

export default Input;
