import React from "react";

const ToolTip = ({ ...props }) => (
  <svg
    width="inherit"
    height="inherit"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    title="tooltip-icon"
    {...props}
  >
    <path
      d="M6.328 5.468C6.328 5.61733 6.328 5.78533 6.328 5.972C6.33333 6.15867 6.336 6.35333 6.336 6.556C6.336 6.75333 6.336 6.95067 6.336 7.148C6.336 7.34 6.33333 7.51867 6.328 7.684C6.32267 7.764 6.31467 7.852 6.304 7.948C6.29333 8.03867 6.272 8.124 6.24 8.204C6.208 8.284 6.15733 8.35067 6.088 8.404C6.024 8.452 5.936 8.47333 5.824 8.468C5.65867 8.45733 5.53867 8.38533 5.464 8.252C5.39467 8.11867 5.35467 7.932 5.344 7.692C5.344 7.56933 5.34133 7.428 5.336 7.268C5.336 7.108 5.33333 6.94533 5.328 6.78C5.328 6.61467 5.328 6.45467 5.328 6.3C5.328 6.14 5.328 5.996 5.328 5.868C5.328 5.79333 5.32533 5.70267 5.32 5.596C5.32 5.48933 5.32267 5.38 5.328 5.268C5.33333 5.156 5.34667 5.04933 5.368 4.948C5.38933 4.84133 5.424 4.75333 5.472 4.684C5.50933 4.64133 5.56267 4.60933 5.632 4.588C5.70133 4.56133 5.77333 4.548 5.848 4.548C5.928 4.548 6.00267 4.56133 6.072 4.588C6.14667 4.61467 6.2 4.652 6.232 4.7C6.25333 4.732 6.26933 4.77733 6.28 4.836C6.29067 4.89467 6.29867 4.96133 6.304 5.036C6.31467 5.10533 6.32 5.18 6.32 5.26C6.32533 5.33467 6.328 5.404 6.328 5.468ZM6.024 3.852C5.85867 3.89467 5.72 3.87867 5.608 3.804C5.50133 3.724 5.43467 3.60667 5.408 3.452C5.38667 3.34 5.39467 3.24133 5.432 3.156C5.46933 3.07067 5.52533 3.00667 5.6 2.964C5.67467 2.916 5.76 2.89467 5.856 2.9C5.952 2.90533 6.048 2.94267 6.144 3.012C6.23467 3.076 6.29867 3.15067 6.336 3.236C6.37867 3.32133 6.392 3.404 6.376 3.484C6.36533 3.564 6.33067 3.63867 6.272 3.708C6.21333 3.772 6.13067 3.82 6.024 3.852Z"
      fill="black"
    />
    <rect x="0.5" y="0.5" width="11" height="11" rx="5.5" stroke="black" />
  </svg>
);

export default ToolTip;
