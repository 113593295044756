import React from "react";

const OpenLock = ({ ...props }) => (
  <svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="inherit"
    xmlns="http://www.w3.org/2000/svg"
    title="open-lock"
    {...props}
  >
    <path
      d="M10.5 16H1.5C0.671874 16 0 15.3281 0 14.5V7.5C0 6.67187 0.671874 6 1.5 6H10.5C11.3281 6 12 6.67187 12 7.5V14.5C12 15.3281 11.3281 16 10.5 16ZM1.5 7C1.225 7 0.999999 7.225 0.999999 7.5V14.5C0.999999 14.775 1.225 15 1.5 15H10.5C10.775 15 11 14.775 11 14.5V7.5C11 7.225 10.775 7 10.5 7H1.5Z"
      fill="inherit"
    />
    <path
      d="M7.24374 0.57814C7.24374 0.900015 6.94687 1.13439 6.63437 1.06564C6.44687 1.02501 6.25 1.00314 6.05 1.00001C4.36875 0.97189 3 2.35626 3 4.03751V6.48438C3 6.75313 2.79375 6.98751 2.52187 7.00001C2.23437 7.01251 2 6.78438 2 6.50001V4.04689C2 1.83751 3.80625 -0.00623415 6.01562 1.58433e-05C6.30312 1.58433e-05 6.58437 0.0312658 6.85312 0.0906408C7.08124 0.140641 7.24374 0.343766 7.24374 0.57814Z"
      fill="inherit"
    />
  </svg>
);

export default OpenLock;
