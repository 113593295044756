import React from "react";

const LessThan = ({ ...props }) => (
  <svg
    width="11"
    height="18"
    viewBox="0 0 11 18"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.41436 9.00001L10.7073 16.2929L9.29304 17.7071L0.585938 9.00001L9.29304 0.292908L10.7073 1.70712L3.41436 9.00001Z"
      fill="inherit"
    />
  </svg>
);

export default LessThan;
