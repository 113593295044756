import React from "react";
import { Doughnut } from "react-chartjs-2";
import { array, bool } from "prop-types";
import { colors } from "../../utils/theme";

const propTypes = {
  /** background Color of each section in Doughnut chart */
  backgroundColor: array,
  /** array of values */
  chartData: array,
  /** indicates inside text is needed or not */
  insideTextNeeded: bool,
  /** labels for each bar */
  labels: array,
  /** indicates legened is required or not */
  legend: bool,
  /** array of string inside chart */
  text: array,
  /** indicates tooltip is required or not */
  tooltip: bool
};

const defaultProps = {
  backgroundColor: [colors.accent[5], colors.accent[3], colors.accent[1]],
  insideTextNeeded: false,
  labels: [],
  legend: false,
  tooltip: true
};

const DoughnutChart = ({
  backgroundColor,
  chartData,
  insideTextNeeded,
  labels,
  legend,
  text,
  tooltip
}) => {
  const data = {
    labels: labels,
    datasets: [
      {
        data: chartData,
        backgroundColor: backgroundColor,
        borderWidth: 0
      }
    ]
  };
  const options = {
    cutoutPercentage: 70,
    legend: {
      display: legend,
      onClick: e => e.stopPropagation()
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      enabled: tooltip
    }
  };
  const plugins = [
    {
      beforeDraw: function(chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;

        ctx.restore();
        var fontSize = (height / 160).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.fillText(text && text[0] && text[0], width / 2, height / 2);

        ctx.font = fontSize / 2 + "em sans-serif";
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.fillText(text && text[1] && text[1], width / 2, height / 1.6);
        ctx.save();
      }
    }
  ];

  return (
    <>
      <Doughnut
        data={data}
        options={options}
        key={text ? text[0] : 0}
        {...(insideTextNeeded ? { plugins: plugins } : {})}
      />
    </>
  );
};

DoughnutChart.propTypes = propTypes;
DoughnutChart.defaultProps = defaultProps;
export default DoughnutChart;
