import styled from "styled-components"

import {shouldForwardProp} from "../shared"
import {h3Variants} from "./variants"
import textProps from "./shared"

const H3 = styled.h3.withConfig({
  shouldForwardProp
})`
  ${textProps};
  ${h3Variants};
`

export default H3
