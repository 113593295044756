import styled from "styled-components"

import {shouldForwardProp} from "../shared"
import textProps from "./shared"
import {bodyVariants} from "./variants"

const P = styled.p.withConfig({
  shouldForwardProp
})`
  ${textProps};
  ${bodyVariants};
`

export default P
