const convertDate = (inputFormat, separator = "/", format) => {
  function pad(s) {
    return s < 10 ? "0" + s : s;
  }
  function dateStr(date) {
    if (date === "01" || date === "1") {
      return "st";
    }
    if (date === "02" || date === "2") {
      return "nd";
    }
    if (date === "03" || date === "3") {
      return "rd";
    } else {
      return "th";
    }
  }
  function monthStr() {
    return [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
  }
  var d = new Date(inputFormat);
  if (format === "M") {
    return `${pad(d.getDate())}${dateStr(pad(d.getDate()))} ${
      monthStr()[parseInt(pad(d.getMonth()))]
    } ${d.getFullYear()}`;
  }
  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join(
    separator
  );
};
export default convertDate;
