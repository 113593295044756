import {css} from "styled-components"

import {defaultSystemProps} from "../shared"

// fontSizes: [10, 12, 14, 16, 18, 20, 24, 28, 32, 36, 42, 48, 54]
/**
 * By default text primitives will have a margin of 0, but can pass preserveUserAgentMargins prop to, well, preserve the user agent stylesheet margins.
 * This is needed to update text tags (including styled components) that rely on user agent margins, so they can be turned into primitives without changing their styles.
 * Do a hard check as a boolean, no truthy values allowed >:D
 * I think this is safe, but could add a default value of false to the Text component in index.js here if desired for more surety.
 */
export default css`
  margin: ${({preserveUserAgentMargins}) =>
    preserveUserAgentMargins === true ? null : 0};
  ${defaultSystemProps};
`
