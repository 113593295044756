import React, { useEffect } from "react";
import { array, bool, func, node, number, oneOfType, string } from "prop-types";
import Primitives from "../primitives";
import Close from "../glyphs/elements/close";
import { colors } from "../utils/theme";
import Button from "../button";

const propTypes = {
  /** vertical alignment */
  align: string,
  /** Border around modal */
  border: string,
  /** Border radius around modal */
  borderRadius: number,
  /** Props for button */
  buttons: array,
  /** Content for modal  */
  content: node,
  /** Handler function for close button */
  closeHandler: func,
  /** Height of close button */
  closeHeight: string,
  /** If modal has close button */
  haveCloseButton: bool,
  /** Height of modal */
  height: oneOfType([number, string]),
  /** Boolean if modal is open or not */
  open: bool,
  /** Padding bottom for modal container */
  pb: number,
  /** Padding top for modal container */
  pt: number,
  /** Horizontal padding for modal container */
  px: number,
  /** Button for modal story */
  storybookButton: bool,
  /** Width of modal */
  width: oneOfType([number, string]),
  /** justify content */
  justifyContent: string,
  /** height of content */
  contentHeight: number
};
const defaultProps = {
  haveCloseButton: true,
  height: 1,
  pb: 55,
  pt: 64,
  px: 56,
  justifyContent: "center"
};
/** Modal Component */
const Modal = ({
  align = "center",
  border,
  borderRadius,
  buttons,
  closeHandler,
  closeHeight,
  content,
  haveCloseButton,
  height,
  open,
  pb,
  pt,
  px,
  storybookButton,
  width,
  justifyContent,
  contentHeight
}) => {
  useEffect(() => {
    window.document.body.style.overflow = open ? "hidden" : "auto";
    return () => (window.document.body.style.overflow = "auto");
  }, [open]);
  return (
    <>
      <Primitives.Flex
        alignItems={align}
        backgroundColor="shadow-grey"
        display={!open && "none"}
        height={1}
        justifyContent={justifyContent}
        left={0}
        onClick={closeHandler}
        overflowY="auto"
        position="fixed"
        top={0}
        width={1}
        zIndex={4}
      >
        <Primitives.Box my={align === "flex-start" ? 66 : 0}>
          <Primitives.Flex
            backgroundColor="white"
            border={border}
            borderRadius={borderRadius}
            flexDirection="column"
            onClick={e => e.stopPropagation()}
            position="relative"
            pb={pb}
            pt={pt}
            px={px}
            width={width}
            height={height}
            overflowY="auto"
          >
            <Primitives.Flex
              width={1}
              height={contentHeight}
              flexDirection="column"
            >
              {haveCloseButton && (
                <Primitives.Flex
                  cursor="pointer"
                  onClick={closeHandler}
                  position="absolute"
                  right={2}
                >
                  <Close height={closeHeight} />
                </Primitives.Flex>
              )}
              {content}
            </Primitives.Flex>

            <Primitives.Flex
              alignSelf="flex-end"
              mt={4}
              {...(!buttons && { display: "none" })}
            >
              {buttons &&
                buttons.map((button, index) => (
                  <Primitives.Flex key={index} mr={1}>
                    <Button
                      active={button.active}
                      backgroundColor={button.backgroundColor}
                      color={button.color}
                      clickHandler={button.clickHandler}
                      hover={button.hover}
                      label={button.label}
                      px={2}
                      py={2}
                    />
                  </Primitives.Flex>
                ))}
            </Primitives.Flex>
          </Primitives.Flex>
        </Primitives.Box>
      </Primitives.Flex>
      {storybookButton && !open && (
        <Primitives.Flex onClick={closeHandler}>
          <Button
            backgroundColor={colors.primary[3]}
            color="white"
            hover={{ backgroundColor: colors.primary[6], color: "white" }}
            label="Click to open modal"
            px={2}
            py={2}
          />
        </Primitives.Flex>
      )}
    </>
  );
};
Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;
export default Modal;
