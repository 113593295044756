import React from "react";
import { array, bool, func, number, oneOfType, string } from "prop-types";
import Primitives from "../primitives";
import styled from "styled-components";
import Button from "../button/index";
import SearchIcon from "../glyphs/elements/search";
import { colors } from "../utils/theme";

const propTypes = {
  /** check focus of input */
  active: bool,
  /**if autofocus on render */
  autofocus: bool,
  /**  blur handler function */
  blurHandler: func,
  /** Border around the input box */
  border: oneOfType([number, string]),
  /** Bottom radius of input box */
  borderRadius: number,
  /** Color of input box */
  borderColor: string,
  /** Click handler function */
  clickHandler: func,
  /** Change handler function */
  changeHandler: func,
  /** Boolean value if input is disabled or not */
  isDisabled: bool,
  /** Text color of input box */
  color: oneOfType([bool, string]),
  /** Field name for input */
  fieldName: string,
  /* flex property */
  flex: number,
  /**  focus handler function */
  focusHandler: func,
  /** Font familiy of input text */
  fontFamily: string,
  /** Font size of input text */
  fontSize: oneOfType([number, string]),
  /** Font style of input text */
  fontStyle: string,
  /** Font weight of input text */
  fontWeight: number,
  /** If input has icon/button it is received in glyph */
  glyph: string,
  /** Horizontal padding for glyph icon */
  glyphPaddingX: oneOfType([string, number, array]),
  /** Vertical padding for glyph icon */
  glyphPaddingY: oneOfType([string, number, array]),
  /** How much space to be given aroung the letters */
  letterSpacing: string,
  /** Line height of texts in input */
  lineHeight: oneOfType([string, number]),
  /** function to call when clicking Enter button */
  maxLength: number,
  onKeyDown: func,
  /** Placeholder in input field */
  placeholder: string,
  /** Horizontal padding */
  px: oneOfType([string, number]),
  /** Vertical padding */
  py: oneOfType([string, number]),
  /** If input is Compulsory */
  required: bool,
  /** If input is readOnly */
  readOnly: bool,
  /** Text Align */
  textAlign: string,
  /** Input type */
  type: string,
  /**Input value */
  value: oneOfType([string, number]),
  /** input width */
  width: oneOfType([string, number])
};
const defaultProps = {
  border: "1px solid",
  borderColor: colors.text[2],
  borderRadius: 4,
  fieldName: "id",
  fontFamily: "Lato",
  fontSize: 1,
  fontStyle: "normal",
  fontWeight: 1,
  glyphPaddingX: "11px",
  glyphPaddingY: "11px",
  letterSpacing: "0.648px",
  lineHeight: 4,
  px: "10px",
  textAlign: "left"
};
const InputContainer = styled(Primitives.Input)`
  outline: none;
  &:focus,
  &:active {
    border: ${props => !props.isDisabled && `1px solid ${props.borderColor}`};
    outline: ${colors.text};
  }
  &::placeholder {
    color: ${props => props.color};
  }
  &[type="number"]::-webkit-inner-spin-button,
  &input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
  &[type="number"]:disabled {
    background: white;
  }
  &[type="number"]::placeholder {
    color: ${props => props.color};
    text-align: center;
  }
  &[type="number"] {
    text-align: center;
    -moz-appearance: textfield;
  }
  &[type="file"] {
    display: none;
  }
  & {
    text-align: ${props => props.textAlign};
  }
`;
const Input = ({
  active,
  autofocus = false,
  blurHandler,
  border,
  borderColor,
  borderRadius,
  clickHandler,
  color,
  changeHandler,
  isDisabled,
  flex,
  focusHandler,
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  glyph,
  glyphPaddingX,
  glyphPaddingY,
  letterSpacing,
  lineHeight,
  onKeyDown,
  fieldName,
  placeholder,
  px,
  py,
  required,
  readOnly,
  textAlign,
  type,
  value,
  width = 1
}) => (
  <Primitives.Flex flex={flex} padding={0}>
    <InputContainer
      autoComplete="off"
      active={active ? 1 : 0}
      border={border}
      borderColor={isDisabled ? "white" : borderColor}
      borderRadius={borderRadius}
      color={color ? color : ""}
      disabled={isDisabled}
      fontFamily={fontFamily}
      fontSize={fontSize}
      fontStyle={fontStyle}
      fontWeight={fontWeight}
      letterSpacing={letterSpacing}
      lineHeight={lineHeight}
      name={fieldName}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      px={px}
      py={py}
      required={required}
      readOnly={readOnly}
      textAlign={textAlign}
      type={type}
      value={value ? value : ""}
      width={glyph ? "calc(100% - 60px)" : width}
      onBlur={blurHandler}
      onFocus={focusHandler}
      onChange={changeHandler}
      autoFocus={autofocus}
    ></InputContainer>
    <Primitives.Flex marginLeft="-3px">
      {glyph && (
        <Button
          backgroundColor={active ? colors.primary[3] : "white"}
          border="1px solid"
          borderColor={active ? colors.primary[3] : colors.text[2]}
          borderBottomLeftRadius={0}
          borderTopLeftRadius={0}
          clickHandler={() => clickHandler()}
          glyph={<SearchIcon fill={active ? "white" : colors.text[0]} />}
          active={{
            backgroundColor: colors.primary[3],
            color: "white",
            fill: "white"
          }}
          hover={{
            backgroundColor: colors.primary[6],
            color: "inherit",
            fill: "white"
          }}
          px={glyphPaddingX}
          py={glyphPaddingY}
        />
      )}
    </Primitives.Flex>
  </Primitives.Flex>
);
Input.propTypes = propTypes;
Input.defaultProps = defaultProps;
export default Input;
