import React, { useState } from "react";
import { colors } from "../utils/theme";
import {
  array,
  bool,
  func,
  number,
  object,
  oneOfType,
  string
} from "prop-types";
import Primitives from "../primitives";
import styled from "styled-components";
import Sorting from "../glyphs/elements/sorting";
import AscendingSort from "../glyphs/elements/ascending-sort";
import DescendingSort from "../glyphs/elements/descending-sort";
import ToolTipComponent from "../tool-tip/ToolTipComponent";
import checkEmptyNull from "../utils/check-empty-null";
import CreateFooter from "./footer";
import useSortableData from "../utils/hooks/useSortableData";
import ClosedLock from "../glyphs/elements/closed-lock";
import OpenLock from "../glyphs/elements/open-lock";

const propTypes = {
  alternateRowColor: string,
  /** Background color of header */
  backgroundColor: object,
  /**Boolean for making the whole row bold */
  boldRow: bool,
  /** Border For rows */
  border: string,
  /** Border Bottom for each row */
  borderBottom: oneOfType([object, number, string]),
  /** index where border colour to be present */
  borderIndex: number,
  /** Text color of columns and rows text */
  color: object,
  /* custom col width */
  columnWidth: array,
  /** row click handler */
  clickHandler: func,
  /** Select column for click handler */
  columnIndex: number,
  /** Cursor type for each row */
  cursor: string,
  /** Table data to be recieved */
  data: array,
  /** Array specifying data type of each columns */
  dataTypes: array,
  /** if the first colun is fixed or not */
  firstColumnFixed: bool,
  /** Font family of the text */
  fontFamily: string,
  /** Font size of the text */
  fontSize: object,
  /** Font style of the text */
  fontStyle: string,
  /** Font weight of the text */
  fontWeight: object,
  /** frozen table column column number */
  freeze: number,
  /** custom header tabs */
  column: array,
  /** if header is clicakble */
  headClick: bool,
  /** Clcking the head of table */
  headClickHandler: func,
  /** In the table, Integer value should be shown with Lkhs, Crores or not */
  isLakhsCroresNeeded: bool,
  /** no sorting columns */
  noSortingColumns: array,
  /** If table is to striped or not */
  striped: bool,
  /** If table is to striped columnwise or not */
  stripedColumns: oneOfType([bool, string]),
  /** text jutsify */
  justify: string,
  /** Line height of the text */
  lineHeight: object,
  /** cell padding */
  p: object,
  /** Padding bottom for column and row */
  pb: object,
  /** padding left */
  pl: number,
  /** padding left */
  pr: number,
  /** Padding top for column and row */
  pt: object,
  /** Selected/clicked row in table */
  selected: number,
  /** if table needs sorting */
  sorting: bool,
  /** sorting type asc or desc */
  sortingType: string,
  /** sorting index where based on sorting type sorting glyphs will be shown */
  sortingIndex: number,
  /** column index in table with tooltip information */
  toolTipText: object,
  /** table width needed only for frozen columns */
  width: oneOfType([string, number]),
  /** Max height of the Table component */
  height: number,
  /** bool value to indicate if footer required */
  footer: bool,
  /** box shadow of footer */
  footShadow: string,
  /**For adding responsiveness */
  isResponsive: bool,
  /**For passing responsiveWidth */
  responsiveWidth: oneOfType([string, array]),
  /** Used for setting min and max height */
  responsiveHeight: array,
  /** used for showing lock icon */
  displayLockIcon: array
};

const defaultProps = {
  backgroundColor: { head: "#F2F4FF", footer: "#F2F4FF" },
  color: {
    head: colors.text[0],
    row: colors.text[0],
    footer: colors.text[0],
    selected: "#EDF4FF"
  },
  cursor: "default",
  borderBottom: { head: "0px", row: "1px solid" },
  firstColumnFixed: false,
  fontFamily: "Lato",
  fontSize: { head: 1, row: 1, footer: 1 },
  fontStyle: "normal",
  fontWeight: { head: 2, row: 1, footer: 2 },
  isLakhsCroresNeeded: false,
  striped: false,
  columnIndex: 0,
  sortingType: "desc",
  lineHeight: { head: 3, row: 1.5, footer: 3 },
  p: { headContainer: "12px", row: "12px", footerContainer: "12px" },
  pb: { headContainer: 15, row: 3 },
  pt: { headContainer: 0, row: 18 },
  pl: 0,
  pr: 0,
  footer: true,
  alternateRowColor: "#F8F8F8",
  footShadow: "0px -2px 8px rgba(0, 0, 0, 0.15)",
  isResponsive: false
};

export const RowContainer = styled(Primitives.Flex)`
  &:hover,
  &:active {
    background-color: #edf4ff;
    border-color: ${colors["light-blue"]};
    font-weight: ${props => props.hasClickHandler && 700};
  }
  &:hover > div {
    background-color: inherit;
  }
  &:hover .displayOnHover {
    display: flex;
  }
  word-break: break-word;
`;

const sortIcon = {
  desc: <DescendingSort />,
  asc: <AscendingSort />
};
export const findAligment = (column, index) =>
  column &&
  column[index] &&
  column[index]["align"] &&
  (column[index]["align"] === "end" || column[index]["align"] === "flex-end")
    ? "right"
    : "left";

const responsiveLock = (
  displayLockIcon,
  toggleIsFirstColumnFixed,
  isFirstColumnFixed
) => (
  <Primitives.Box
    display={displayLockIcon}
    onClick={e => {
      e.stopPropagation();
      toggleIsFirstColumnFixed(!isFirstColumnFixed);
    }}
    p={1}
    height={"16px"}
  >
    {isFirstColumnFixed ? <ClosedLock /> : <OpenLock fill="#F58158" />}
  </Primitives.Box>
);
const Table = ({
  alternateRowColor,
  backgroundColor,
  boldRow,
  border,
  borderBottom,
  borderIndex,
  color,
  clickHandler,
  columnIndex,
  cursor,
  data = [],
  firstColumnFixed,
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  freeze,
  column,
  headClick,
  headClickHandler,
  isLakhsCroresNeeded = false,
  striped,
  stripedColumns,
  justify,
  lineHeight,
  p,
  pl,
  pr,
  selected,
  sorting,
  sortingType,
  sortingIndex,
  toolTipText,
  width,
  footer,
  footShadow,
  isResponsive,
  responsiveWidth,
  responsiveHeight,
  displayLockIcon
}) => {
  const { rows, customHeadClickHandler, sortConfig } = useSortableData(
    data.length > 0 && data.map(item => Object.values(item))
  );
  const initialSortingType = sortingType;

  if (sortConfig) {
    sortingType = sortConfig.sortingType;
    sortingIndex = sortConfig.index;
  }

  const columnWidth =
    column && column.map(item => (item["width"] ? item["width"] : 1));

  const head =
    (column && column) ||
    (data.length > 0 &&
      Object.keys(data[0]).map(title => ({
        heading: title
          .split("_")
          .map(item => item)
          .join(" ")
      })));
  //const rows = data.length > 0 && data.map(item => Object.values(item));
  const rowColor = (index, isFrozen) => {
    if (isFrozen) {
      return "transparent";
    } else {
      return striped && selected !== index
        ? index % 2 === 0
          ? "white"
          : alternateRowColor
          ? alternateRowColor
          : colors["light-blue"]
        : selected === index && color["selected"];
    }
  };

  const colOpacity = (isFrozen, index) => (isFrozen && index >= freeze ? 0 : 1);

  let timeout;
  const [active, setActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState();
  const [isFirstColumnFixed, toggleIsFirstColumnFixed] = useState(
    firstColumnFixed
  );

  const showTip = index => {
    setActiveIndex(index);
    timeout = setTimeout(() => {
      setActive(true);
    }, 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  const createHeader = isFrozen => (
    <Primitives.Flex
      position={isFirstColumnFixed && "sticky"}
      top={isFirstColumnFixed && "-1px"}
      backgroundColor={backgroundColor && !isFrozen && backgroundColor.head}
      color={color.head}
      flexDirection="row"
      fontSize={fontSize.head}
      fontWeight={fontWeight.head}
      lineHeight={lineHeight.head}
      pl={pl}
      pr={pr}
      zIndex={isFirstColumnFixed && 2}
      minWidth={isResponsive && responsiveWidth}
      boxSizing="border-box"
    >
      {head.length &&
        head.map((item, index) => {
          return (
            item["heading"] && (
              <Primitives.Flex
                alignItems="center"
                flex={item["width"] ? item["width"] : 1}
                flexDirection="column"
                position={
                  index === 0 && isFirstColumnFixed ? "sticky" : "static"
                }
                left={isFirstColumnFixed && "-1px"}
                backgroundColor={item["background"]}
                borderBottom={
                  borderIndex && index in borderIndex
                    ? borderIndex[index]
                    : borderBottom.head
                }
                p={p.headContainer}
                key={index}
                zIndex={index === 0 && isFirstColumnFixed && 1}
                background="inherit"
              >
                {item["mainHeading"] && (
                  <Primitives.Text minHeight={30}>
                    {item["mainHeading"]}
                  </Primitives.Text>
                )}
                {(headClick || sorting) && item["sort"] !== false ? (
                  <Primitives.Button
                    backgroundColor={
                      isFrozen
                        ? backgroundColor
                          ? backgroundColor.head
                          : "white"
                        : "transparent"
                    }
                    display="flex"
                    flex={1}
                    id="tableHeader"
                    justifyContent={
                      column && column[index]["align"]
                        ? column[index]["align"]
                        : "space-between"
                    }
                    alignItems="center"
                    onClick={() =>
                      headClickHandler
                        ? headClickHandler(index)
                        : customHeadClickHandler(
                            index,
                            initialSortingType,
                            column[index]["type"]
                          )
                    }
                    textAlign={findAligment(column, index)}
                    opacity={colOpacity(isFrozen, index)}
                    onMouseEnter={() => {
                      toolTipText &&
                        toolTipText[index] !== undefined &&
                        showTip(index);
                    }}
                    onMouseLeave={hideTip}
                    position="relative"
                    width={1}
                  >
                    <Primitives.Flex>
                      {item["heading"] && item["heading"]}
                    </Primitives.Flex>

                    <Primitives.Flex alignItems="center">
                      {isResponsive &&
                        index === 0 &&
                        responsiveLock(
                          displayLockIcon,
                          toggleIsFirstColumnFixed,
                          isFirstColumnFixed
                        )}
                      <Primitives.Box ml={1}>
                        {sortingIndex === index ? (
                          sortIcon[sortingType]
                        ) : (
                          <Sorting fill={colors.text[0]} />
                        )}
                      </Primitives.Box>
                    </Primitives.Flex>
                    {active &&
                      activeIndex === index &&
                      toolTipText &&
                      toolTipText[index] && (
                        <ToolTipComponent
                          top={
                            document.querySelector("#tableHeader").offsetHeight
                          }
                          {...(activeIndex ===
                            parseInt(
                              Object.keys(toolTipText)[
                                Object.keys(toolTipText).length - 1
                              ]
                            ) && {
                            width: 80
                          })}
                        >
                          {toolTipText[index] && toolTipText[index]}
                        </ToolTipComponent>
                      )}
                  </Primitives.Button>
                ) : (
                  <Primitives.Flex
                    backgroundColor={
                      stripedColumns &&
                      index % 2 === (stripedColumns === "even" ? 0 : 1)
                        ? colors["purple"]
                        : isFrozen
                        ? backgroundColor
                          ? backgroundColor.head
                          : "white"
                        : "transparent"
                    }
                    boxShadow={
                      isFrozen && index === freeze - 1
                        ? `20px 0px 20px -20px rgba(0,0,0,0.8)`
                        : ""
                    }
                    flex={1}
                    justifyContent={
                      column && column[index]["align"]
                        ? column[index]["align"]
                        : "space-between"
                    }
                    alignItems="center"
                    lineHeight={lineHeight.head || 1}
                    opacity={colOpacity(isFrozen, index)}
                    textAlign={findAligment(column, index)}
                    width={1}
                  >
                    {item["heading"] && item["heading"]}
                    {isResponsive &&
                      index === 0 &&
                      responsiveLock(
                        displayLockIcon,
                        toggleIsFirstColumnFixed,
                        isFirstColumnFixed
                      )}
                  </Primitives.Flex>
                )}
              </Primitives.Flex>
            )
          );
        })}
    </Primitives.Flex>
  );

  const createRows = isFrozen => (
    <>
      {rows.length &&
        rows.map((row, index) => (
          <RowContainer
            backgroundColor={rowColor(index, isFrozen) || "#fff"}
            borderBottom={borderBottom.row}
            borderColor={
              selected === index ? colors["light-blue"] : colors["pattens-blue"]
            }
            color={color.row}
            cursor={cursor}
            flexDirection="row"
            fontSize={fontSize.row}
            fontWeight={
              boldRow && index === rows.length - 1 ? 2 : fontWeight.row
            }
            {...(clickHandler && { hasClickHandler: true })}
            striped={striped || stripedColumns ? "true" : "false"}
            lineHeight={lineHeight.row}
            onClick={() => clickHandler && clickHandler(row, index)}
            pl={pl}
            key={index}
          >
            {row.map((item, colIndex) => (
              <Primitives.Flex
                position={
                  colIndex === 0 && isFirstColumnFixed ? "sticky" : "relative"
                }
                zIndex={colIndex === 0 && isFirstColumnFixed && 1}
                background={colIndex === 0 && "inherit"}
                left={isFirstColumnFixed && "-1px"}
                backgroundColor={
                  stripedColumns &&
                  colIndex % 2 === (stripedColumns === "even" ? 0 : 1)
                    ? colors["purple"]
                    : ""
                }
                borderRight={colIndex < row.length - 1 && border && border}
                boxShadow={
                  isFrozen && colIndex === freeze - 1
                    ? ` 20px 0px 20px -20px rgba(0,0,0,0.8)`
                    : ""
                }
                flex={columnWidth ? columnWidth[colIndex] : 1}
                justifyContent={
                  column && column[colIndex] && column[colIndex]["align"]
                    ? column[colIndex]["align"]
                    : colIndex === 0
                    ? "flex-start"
                    : justify
                }
                {...(clickHandler &&
                  colIndex === columnIndex && { color: "#317FF7" })}
                fontWeight={clickHandler && selected === index && 2}
                textAlign={findAligment(column, colIndex)}
                key={colIndex}
                opacity={colOpacity(isFrozen, colIndex)}
                // position="relative"
                p={p.row}
              >
                {checkEmptyNull(
                  item,
                  column
                    ? column[colIndex] &&
                        column[colIndex]["type"] &&
                        column[colIndex]["type"]
                    : typeof item,
                  isLakhsCroresNeeded
                )}
              </Primitives.Flex>
            ))}
          </RowContainer>
        ))}
    </>
  );
  return (
    <Primitives.Flex
      flexDirection="column"
      width={1}
      minHeight={isResponsive && responsiveHeight && responsiveHeight[0]}
      maxHeight={
        isResponsive && responsiveHeight ? responsiveHeight[1] : "100%"
      }
      position="relative"
      overflow="auto"
    >
      {createHeader()}
      <Primitives.Flex
        border={border && border}
        flexDirection="column"
        fontFamily={fontFamily}
        fontStyle={fontStyle}
        width={freeze > 0 ? width : 1}
        minHeight="inherit"
        minWidth={isResponsive && responsiveWidth}
        boxSizing="border-box"
        overflow={!isFirstColumnFixed && "auto"}
      >
        {createRows()}
        {footer && column && column.find(item => item["footerData"]) && (
          <CreateFooter
            column={column}
            fontSize={fontSize}
            fontWeight={fontWeight}
            lineHeight={lineHeight}
            p={p}
            pl={pl}
            pr={pr}
            color={color}
            justify={justify}
            backgroundColor={backgroundColor}
            footShadow={footShadow}
            isFirstColumnFixed={isFirstColumnFixed}
          ></CreateFooter>
        )}
        {freeze > 0 && (
          <Primitives.Box position="fixed" width={width}>
            {createHeader(true)}
            {createRows(true)}
          </Primitives.Box>
        )}
      </Primitives.Flex>
    </Primitives.Flex>
  );
};

Table.propTypes = propTypes;
Table.defaultProps = defaultProps;
export default Table;
