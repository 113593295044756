import React from "react";

const MoreThan = ({ ...props }) => (
  <svg
    width="11"
    height="18"
    viewBox="0 0 11 18"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.58586 9.00001L0.292969 1.70712L1.70718 0.292908L10.4143 9.00001L1.70718 17.7071L0.292969 16.2929L7.58586 9.00001Z"
      fill="inherit"
    />
  </svg>
);
export default MoreThan;
