import React from "react";
import Primitives from "../primitives";
import { bool } from "prop-types";
import { colors } from "../utils/theme";
import "./loader.css";
import loadingGif from "./loader-glyph/Loader.gif";

const propTypes = {
  /** pass bool as true when only spinner is required */
  onlySpinner: bool
};
const defaultProps = {
  onlySpinner: false
};

/* loading component */
const Loading = ({ onlySpinner }) => (
  <Primitives.Flex flexDirection="column" alignItems="center">
    {onlySpinner ? (
      <svg
        className="spinner"
        width="42px"
        height="42px"
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
        title="spinner"
      >
        <circle
          className="path"
          fill="none"
          strokeWidth="6"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        ></circle>
      </svg>
    ) : (
      <Primitives.Flex flexDirection="column" height={300} width={300}>
        <img alt="loading..." height="100%" src={loadingGif} width="100%" />
        <Primitives.Text
          fontFamily="Lato"
          margin="auto"
          mt="-70px"
          color={colors.text[1]}
          letterSpacing={6}
        >
          LOADING ...
        </Primitives.Text>
      </Primitives.Flex>
    )}
  </Primitives.Flex>
);
Loading.propTypes = propTypes;
Loading.defaultProps = defaultProps;
export default Loading;
