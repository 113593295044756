import React from "react";

const ClosedLock = ({ ...props }) => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    title="closed-lock"
    {...props}
  >
    <path
      d="M9.87495 5.17258V3.82293C9.87495 1.7163 7.95169 0 5.58635 0C3.22108 0 1.29317 1.71638 1.29317 3.82293V5.17258C0.939767 5.20218 0.61049 5.36372 0.370855 5.62519C0.131115 5.88656 -0.00125398 6.22864 8.95464e-06 6.58331V11.756C8.95464e-06 12.1302 0.148638 12.4889 0.41315 12.7536C0.677767 13.0181 1.03653 13.1667 1.41073 13.1667H9.87509C10.2493 13.1667 10.608 13.0181 10.8727 12.7536C11.1372 12.4889 11.2858 12.1302 11.2858 11.756V6.58331C11.2858 6.2091 11.1372 5.85035 10.8727 5.58572C10.6081 5.32121 10.2492 5.17258 9.87495 5.17258ZM5.58635 0.940403C7.42975 0.940403 8.93447 2.23357 8.93447 3.82285V5.1725H2.23339V3.82285C2.23339 2.23349 3.76194 0.940403 5.58635 0.940403ZM10.3452 11.756C10.3452 11.8807 10.2957 12.0003 10.2075 12.0885C10.1193 12.1767 9.99965 12.2262 9.87495 12.2262H1.4106C1.2859 12.2262 1.16624 12.1767 1.07807 12.0885C0.989903 12.0003 0.940358 11.8807 0.940358 11.756V6.58331C0.940358 6.45861 0.989903 6.33895 1.07807 6.25078C1.16624 6.16261 1.2859 6.11306 1.4106 6.11306H9.87495C9.99965 6.11306 10.1193 6.16261 10.2075 6.25078C10.2957 6.33895 10.3452 6.45861 10.3452 6.58331V11.756Z"
      fill="#F58158"
    />
    <path
      d="M6.11299 7.99414H5.17251C5.00446 7.99414 4.84922 8.08378 4.76525 8.22926C4.68128 8.37474 4.68128 8.55402 4.76525 8.6995C4.84922 8.84498 5.00446 8.93462 5.17251 8.93462V10.3453C5.17251 10.5134 5.26214 10.6686 5.40763 10.7526C5.55311 10.8366 5.73239 10.8366 5.87787 10.7526C6.02335 10.6686 6.11299 10.5134 6.11299 10.3453V8.93462C6.28104 8.93462 6.43627 8.84499 6.52025 8.6995C6.60422 8.55402 6.60422 8.37474 6.52025 8.22926C6.43627 8.08378 6.28103 7.99414 6.11299 7.99414Z"
      fill="#F58158"
    />
  </svg>
);

export default ClosedLock;
