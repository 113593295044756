import styled from "styled-components"

import {shouldForwardProp} from "../shared"
import textProps from "./shared"

const H1 = styled.h1.withConfig({
  shouldForwardProp
})`
  ${textProps};
`

export default H1
