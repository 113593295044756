import React from "react";

const Search = ({ ...props }) => (
  <svg
    width="17.85"
    height="16"
    viewBox="0 0 18 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8411 12.0754L9.14431 8.3785C9.86039 7.49397 10.2915 6.37 10.2915 5.14584C10.2915 2.30859 7.98298 0 5.14576 0C2.30854 0 0 2.30857 0 5.14582C0 7.98307 2.30857 10.2917 5.14579 10.2917C6.36993 10.2917 7.49389 9.8605 8.37841 9.14441L12.0752 12.8413C12.1809 12.9469 12.3195 13 12.4582 13C12.5969 13 12.7355 12.9469 12.8412 12.8413C13.0529 12.6295 13.0529 12.2872 12.8411 12.0754ZM5.14579 9.20832C2.90547 9.20832 1.08333 7.38616 1.08333 5.14582C1.08333 2.90547 2.90547 1.08332 5.14579 1.08332C7.3861 1.08332 9.20824 2.90547 9.20824 5.14582C9.20824 7.38616 7.38608 9.20832 5.14579 9.20832Z"
    />
  </svg>
);

export default Search;
