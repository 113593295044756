import React, { useState } from "react";
import Primitives from "../primitives";
import { array, bool, number, string, oneOfType, func } from "prop-types";
import ListItem from "./list-item";
import styled from "styled-components";
import { breakPoint, colors } from "../utils/theme";
import Loader from "../loading";

const propTypes = {
  /** activeIndex in list */
  activeIndex: oneOfType([array, string]),
  /** active color for list */
  activeColor: string,
  /** background color for all lists */
  backgroundColor: string,
  /** Bottom position of dropdown */
  bottom: oneOfType([number, string]),
  /** Box shadow around the container */
  boxShadow: oneOfType([number, array]),
  /** What Font family should  text use*/
  color: string,
  /**  name of the dropdown item class*/
  className: string,
  /** For Lazy Loading */
  enableLazyLoading: bool,
  /** What font size should be used on text */
  fontFamily: string,
  /** Font styling for text */
  fontSize: oneOfType([number, array]),
  /** What fontweight should be applied to text */
  fontStyle: string,
  /** What font-weight text should have */
  fontWeight: number,
  /** Hover state of list item */
  hoverweight: number,
  /** hover color for lists */
  hoverColor: string,
  /** Left position of dropdown */
  left: oneOfType([number, string, array]),
  /** Line height of letter of text */
  lineHeight: number,
  /** List max height */
  listMaxHeight: oneOfType([array, string, number]),
  /** List max width */
  listMaxWidth: oneOfType([number, number, string]),
  /** min width */
  minWidth: oneOfType([number, string]),
  /** Array of options to be displayed in list */
  options: oneOfType([array, string]),
  /** search keyword */
  search: string,
  /** Handler function for seleting option */
  selectHandler: func,
  /** Select type of dropdown */
  selectType: string,
  /** whether shadow required */
  shadowRequired: bool,
  /** Right position of dropdown */
  right: oneOfType([number, string]),
  /** Top position of list  */
  top: oneOfType([number, string, array]),
  /**Width of container */
  width: oneOfType([number, string, array])
};
const defaultProps = {
  backgroundColor: "white",
  bottom: "auto",
  boxShadow: 9,
  fontFamily: "Lato",
  fontSize: 1,
  fontStyle: "normal",
  fontweight: 1,
  hoverColor: `${colors["background-light-blue"]}`,
  left: "auto",
  lineHeight: 1,
  listMaxHeight: 195,
  options: [],
  right: "auto",
  shadowRequired: true,
  top: 41,
  width: 1
};
const StyledList = styled.ul`
  padding: 0;
  list-style: none;
`;
const itemsToShowInFirstRender = 40;

const ListDropdown = ({
  activeIndex,
  activeColor,
  backgroundColor,
  bottom,
  boxShadow,
  color,
  className,
  enableLazyLoading,
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  hoverweight,
  hoverColor,
  left,
  lineHeight,
  listMaxHeight,
  listMaxWidth,
  minWidth,
  options,
  search = "",
  selectHandler,
  selectType,
  shadowRequired,
  right,
  top,
  width
}) => {
  const [countOfItemsToShow, updateCountOfItemsToShow] = useState(
    enableLazyLoading && window.innerWidth >= breakPoint
      ? itemsToShowInFirstRender
      : options.length
  );
  const searchedOptions = options.filter(item =>
    search ? item.text.toLowerCase().includes(search.toLowerCase()) : item
  );
  const visibleItems = searchedOptions.slice(0, countOfItemsToShow);
  return (
    <Primitives.Box
      backgroundColor={backgroundColor}
      bottom={bottom}
      boxShadow={shadowRequired && boxShadow}
      color={color}
      fontFamily={fontFamily}
      fontSize={fontSize}
      fontStyle={fontStyle}
      fontWeight={fontWeight}
      left={left}
      lineHeight={lineHeight}
      maxHeight={listMaxHeight}
      maxWidth={listMaxWidth}
      minWidth={minWidth}
      overflowX="scroll"
      overflowY="auto"
      onScroll={e => {
        if (
          e.currentTarget.scrollTop + e.currentTarget.offsetHeight >=
          e.currentTarget.scrollHeight
        ) {
          updateCountOfItemsToShow(
            countOfItemsToShow => countOfItemsToShow + itemsToShowInFirstRender
          );
        }
      }}
      position="absolute"
      right={right}
      top={top}
      width={width}
      whiteSpace="nowrap"
      zIndex={1}
    >
      <StyledList>
        {visibleItems ? (
          visibleItems
            .filter(item =>
              search
                ? item.text.toLowerCase().includes(search.toLowerCase())
                : item
            )
            .map((option, index) => (
              <ListItem
                activeColor={
                  option.page
                    ? JSON.stringify(option.page) ===
                        JSON.stringify(activeIndex) && activeColor
                    : option.text === activeIndex && activeColor
                }
                activeFontweight={option.text === activeIndex && 2}
                className={className}
                key={index}
                option={option}
                hoverColor={hoverColor}
                hoverweight={hoverweight}
                selectHandler={selectHandler && selectHandler}
                selectType={selectType}
              />
            ))
        ) : (
          <Primitives.Flex paddingLeft="40%">
            <Loader onlySpinner={true} />
          </Primitives.Flex>
        )}
      </StyledList>
    </Primitives.Box>
  );
};

ListDropdown.propTypes = propTypes;
ListDropdown.defaultProps = defaultProps;
export default ListDropdown;
