import React from "react";
import Primitives from "../primitives";
import { bool, func, number, object, oneOfType, string } from "prop-types";
import styled from "styled-components";
import { colors, themeSettings } from "../utils/theme";
import Cta from "../cta";

const propTypes = {
  activeColor: oneOfType([bool, string]),
  activeFontweight: oneOfType([bool, number]),
  className: string,
  hoverweight: number,
  hoverColor: string,
  option: object,
  selectHandler: func,
  selectType: string
};

const ListContainer = styled(Primitives.Flex)`
cursor: ${props => props.cursor || "pointer"};
&:hover {
  background: ${colors["background-light-blue"]};
  color: ${colors.text[0]};
  font-weight: ${props => themeSettings.fontWeights[props.hoverweight]};
`;

const ListItem = ({
  activeColor,
  activeFontweight,
  className,
  hoverweight,
  option,
  selectHandler,
  selectType
}) =>
  option.link ? (
    <Primitives.Box backgroundColor={activeColor}>
      <Cta
        color="inherit"
        fontWeight="inherit"
        hover={{ color: "inherit", textdecorationline: false }}
        label={
          <li onClick={() => {}} className={className}>
            <ListContainer hoverweight={hoverweight} px={2} pb={1} pt={1}>
              <Primitives.Text lineHeight={3} color={option.color}>
                {option.text}
              </Primitives.Text>
              {option.glyph && (
                <Primitives.Flex pl={1}>{option.glyph}</Primitives.Flex>
              )}
            </ListContainer>
          </li>
        }
        link={option.link}
        target={option.target ? option.target : "_self"}
        type={option.type ? option.type : "anchor"}
        textdecorationline={false}
      />
    </Primitives.Box>
  ) : (
    <li onClick={() => selectHandler(option, selectType)} className={className}>
      <ListContainer
        hoverweight={hoverweight}
        backgroundColor={activeColor}
        px={2}
        pb={1}
        pt={1}
        alignItems="center"
        cursor={option.cursor}
      >
        {option.glyph && (
          <Primitives.Flex pr={1}>{option.glyph}</Primitives.Flex>
        )}
        <Primitives.Text
          lineHeight={3}
          color={option.color}
          fontWeight={activeFontweight && activeFontweight}
        >
          {option.text}
        </Primitives.Text>
      </ListContainer>
    </li>
  );

ListItem.propTypes = propTypes;

export default ListItem;
