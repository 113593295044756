import { system } from "styled-system";

import { heightTransformer, transitionTransformer } from "./transformers";

const customSystemProps = system({
  boxSizing: true,
  cursor: true,
  fill: {
    property: "fill",
    scale: "colors"
  },
  filter: true,
  height: {
    property: "height",
    transform: heightTransformer
  },
  listStyle: true,
  textDecoration: true,
  textOverflow: true,
  transform: true,
  transition: {
    property: "transition",
    transform: transitionTransformer
  },
  userSelect: true,
  whiteSpace: true,
  wordBreak: true
});

export default customSystemProps;
