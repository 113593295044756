import React from "react";

/** "useSortableData" is a custom hook/func that returns an object "rows" with sorted items,
a function "customHeadClickHandler" to re-sort the items,
& a state "sortConfig" to indicate how the table is sorted.

Moreover, "useSortableData" accepts initial sort state "config =null" */

const useSortableData = (items, config = null) => {
  /** "useState" hook remembers the currently sorted column. 
    "sortConfig" state variable will contain an object with "index" & a "sortingType" */

  const [sortConfig, setSortConfig] = React.useState(config);
  const sortedItems = React.useMemo(() => {
    sortConfig &&
      items.sort((a, b) => {
        a = a[sortConfig.index];
        b = b[sortConfig.index];
        if (sortConfig.type === "number" && typeof a !== "number") {
          a = parseFloat(a.replace(/[^0-9. ]/g, ""));
        }
        if (sortConfig.type === "number" && typeof b !== "number") {
          b = parseFloat(b.replace(/[^0-9. ]/g, ""));
        }
        return a <= b
          ? sortConfig.sortingType === "asc"
            ? -1
            : 1
          : sortConfig.sortingType === "asc"
          ? 1
          : -1;
      });
    return items;
  }, [items, sortConfig]);

  /** "customHeadClickHandler" will accept "index" & "sortingType" then update the state accordingly */

  const customHeadClickHandler = (index, sortingType, type) => {
    /** Initially "sortingType" is set as "asc" or "desc" inside "defaultProps" */

    sortConfig &&
      sortConfig.index === index &&
      (sortingType = sortConfig.sortingType === "asc" ? "desc" : "asc");

    setSortConfig({ index, sortingType, type });
    return true;
  };
  return { rows: sortedItems, customHeadClickHandler, sortConfig };
};
export default useSortableData;
