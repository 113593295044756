import React, { useState } from "react";
import Navigation from "./NavigationComponent";
import {
  array,
  bool,
  number,
  func,
  node,
  object,
  oneOfType,
  string
} from "prop-types";

const propTypes = {
  /**  navigation data */
  data: array,
  id: oneOfType([array, string]),
  justifyContent: string,
  loading: bool,
  navRightComponent: object,
  product: string,
  productLogo: node,
  profileOptions: array,
  root: string,
  selected: number,
  selectHandler: func,
  setCartCollpase: func,
  user: oneOfType([array, object]),
  hideNavData: bool
};

const modifyData = (data, selected) =>
  data &&
  data.map((item, index) =>
    index === selected
      ? { ...item, label: item.label, selected: true }
      : {
          ...item,
          label: item.label
        }
  );

const NavigationContainer = ({
  data,
  id,
  justifyContent,
  loading,
  navRightComponent,
  product,
  productLogo,
  profileOptions,
  root,
  selected,
  selectHandler,
  setCartCollpase,
  user,
  hideNavData
}) => {
  const [userOptionsCollapse, setUserOptionsCollapse] = useState(false);

  ((localStorage.getItem("newUser") &&
    localStorage.getItem("newUser") === "true") ||
    hideNavData) &&
    data.splice(0, 5);

  return (
    <Navigation
      data={modifyData(data, selected)}
      id={id}
      justifyContent={justifyContent}
      loading={loading}
      navRightComponent={navRightComponent}
      product={product}
      productLogo={productLogo}
      profileOptions={profileOptions}
      root={root}
      selectHandler={selectHandler}
      setCartCollpase={setCartCollpase}
      user={user && user.user}
      userOptionsCollapse={userOptionsCollapse}
      setUserOptionsCollapse={setUserOptionsCollapse}
    />
  );
};
NavigationContainer.propTypes = propTypes;
export default NavigationContainer;
