import React from "react";
import { colors } from "../../utils/theme";
import Primitives from "../../primitives";
import { array, bool, number, object, string } from "prop-types";
import { findAligment } from "../TableComponent";

const propTypes = {
  /** Background color of header */
  backgroundColor: object,
  /** Text color of columns and rows text */
  color: object,
  /** custom header tabs */
  column: array,
  /** Font size of the text */
  fontSize: object,
  /** Font weight of the text */
  fontWeight: object,
  //box shadow of footer
  footShadow: string,
  /** footer rows data */
  footerData: array,
  /** In the table, Integer value should be shown with Lkhs, Crores or not */
  isLakhsCroresNeeded: bool,
  /** is frozen */
  isFrozen: bool,
  /** text jutsify */
  justify: string,
  /** Line height of the text */
  lineHeight: object,
  /** cell padding */
  p: object,
  /** padding left */
  pl: number,
  /** padding right */
  pr: number,
  /** used for fixing first colum */
  isFirstColumnFixed: bool
};

const defaultProps = {
  backgroundColor: { footer: "#F2F4FF" },
  color: {
    footer: colors.text[0]
  },
  fontSize: { footer: 1 },
  fontWeight: { footer: 2 },
  isLakhsCroresNeeded: false,
  lineHeight: { footer: 3 },
  p: { footerContainer: 1 },
  pl: 0,
  pr: 0,
  footShadow: "0px -2px 8px rgba(0, 0, 0, 0.15)"
};

const CreateFooter = ({
  column,
  fontSize,
  fontWeight,
  lineHeight,
  p,
  pl,
  pr,
  justify,
  color,
  backgroundColor,
  footShadow,
  isFirstColumnFixed
}) => (
  <Primitives.Flex
    backgroundColor={backgroundColor && backgroundColor.footer}
    color={color.footer}
    flexDirection="row"
    fontSize={fontSize.footer}
    fontWeight={fontWeight.footer}
    lineHeight={lineHeight.footer}
    position="sticky"
    bottom="0"
    pl={pl}
    pr={pr}
    boxShadow={footShadow}
    zIndex={isFirstColumnFixed && 2}
  >
    {column &&
      column.length > 0 &&
      column.map(
        (item, index) =>
          item["footerData"] !== null && (
            <Primitives.Flex
              position={
                index === 0 && isFirstColumnFixed ? "sticky" : "relative"
              }
              left={isFirstColumnFixed && "-1px"}
              flex={column[index]["width"] ? column[index]["width"] : 1}
              p={p.footerContainer}
              key={index}
              justifyContent={
                column && column[index]["align"]
                  ? column[index]["align"]
                  : index === 0
                  ? "flex-start"
                  : justify
              }
              textAlign={findAligment(column, index)}
              zIndex={index === 0 && isFirstColumnFixed && 1}
              background="inherit"
            >
              {item["footerData"]}
            </Primitives.Flex>
          )
      )}
  </Primitives.Flex>
);

CreateFooter.propTypes = propTypes;
CreateFooter.defaultProps = defaultProps;
export default CreateFooter;
