import React from "react";

const Filter = ({ ...props }) => (
  <svg viewBox="0 0 18.33 19.65" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      clipRule="evenodd"
      d="M0.833252 4.98344L7.24992 12.4912V20.5313L12.7499 18.4197V12.4912L19.1666 4.98344V2.75389C19.1666 1.71729 18.3458 0.876953 17.3333 0.876953H2.66659C1.65406 0.876953 0.833252 1.71729 0.833252 2.75389V4.98344ZM17.3333 2.75389V3.69236H2.66659V2.75389H17.3333ZM3.77004 5.56929H16.2298L11.4173 11.2001H8.58254L3.77004 5.56929ZM9.08325 13.077V17.8229L10.9166 17.119V13.077H9.08325Z"
      fill="inherit"
      fillRule="evenodd"
    />
  </svg>
);

export default Filter;
