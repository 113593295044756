import indianNumberFormat from "./indianNumberFormat";
import ConvertMoneyToLakhsCrores from "./convert-money-to-lakh-crores";
import roundOffTwoDecimal from "./round-off-two-decimal";

const emptyValues = [null, "null", "-", "--", ""];
const specials = /(₹|%)/;
const moneyValue = /(L|Cr|K)/;

const checkEmptyNull = (value, valueType, isLakhsCroresNeeded = false) => {
  if (emptyValues.includes(value)) {
    return valueType && valueType === "string" ? "--" : 0;
  } else if (typeof value === "object" || valueType === "object") {
    return value;
  } else if (typeof value == "string") {
    /** handling cases where response already consists of L and K */
    if (moneyValue.test(value)) {
      return value;
    } else if (specials.test(value)) {
      /**  handling null and empty case for values containing rupee and percentage symbols */
      const separators = ["₹", "%"];
      const rupeeValue = value.split(new RegExp(separators.join("|"), "g"));
      /** for rupee symbol, pick second value from array, for percentage symbol pick first value from array */
      /** picks percentage value */
      if (rupeeValue[0]) {
        if (emptyValues.includes(rupeeValue[0].trim())) {
          return `0 %`;
        } else {
          return `${rupeeValue[0]} %`;
        }
      } else {
        /** picks rupee value */
        if (emptyValues.includes(rupeeValue[1].trim())) {
          return `₹ 0`;
        } else if (isLakhsCroresNeeded) {
          return `₹ ${ConvertMoneyToLakhsCrores(rupeeValue[1].trim())}`;
        } else {
          return `₹ ${indianNumberFormat(
            roundOffTwoDecimal(rupeeValue[1].trim())
          )}`;
        }
      }
    }
    return value;
  } else if (typeof value === "number") {
    return indianNumberFormat(roundOffTwoDecimal(value));
  } else {
    return value;
  }
};
export default checkEmptyNull;
