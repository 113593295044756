const getMonthName = monthNumber => {
  if (monthNumber < 0) {
    return "December";
  }
  if (monthNumber > 11) {
    return "January";
  }
  var months = [
    "December",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  return months[++monthNumber];
};
export default getMonthName;
