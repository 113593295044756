import React, { useEffect, useRef } from "react";
import { array, bool, func, node, object, oneOfType, string } from "prop-types";
import styled from "styled-components";
import Cta from "../cta";
import NavItem from "../nav-item";
import Loader from "../loading";
import ListDropdown from "../list-dropdown";
import Primitives from "../primitives";
import { colors } from "../utils/theme";

const propTypes = {
  data: array,
  id: oneOfType([array, string]),
  justifyContent: string,
  loading: bool,
  navRightComponent: object,
  product: string,
  productLogo: node,
  profileOptions: array,
  root: string,
  selectHandler: func,
  setCartCollpase: func,
  user: object,
  userOptionsCollapse: bool,
  setUserOptionsCollapse: func
};

export const StyledImage = styled(Primitives.Image)`
  border-radius: 50%;
  height: 24px;
  width: 24px;
`;

const NavigationComponent = ({
  data,
  id,
  justifyContent,
  loading,
  navRightComponent,
  product,
  productLogo,
  profileOptions,
  root,
  selectHandler,
  user,
  userOptionsCollapse,
  setUserOptionsCollapse
}) => {
  const node = useRef();

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setUserOptionsCollapse(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <Primitives.Box
      background="white"
      borderBottom="1px solid"
      borderColor="athens-gray"
      position="sticky"
      tag="header"
      top={0}
      width={1}
      zIndex={5}
    >
      <Primitives.Flex
        ref={node}
        backgroundColor="white"
        direction="column"
        width={1}
      >
        <Primitives.Flex flexDirection="column">
          <Primitives.Flex flex={1} ml={30}>
            <a
              href={root}
              style={{ display: "inline-flex", textDecoration: "none" }}
            >
              <Primitives.Text
                alignSelf="center"
                color={colors.primary[3]}
                fontFamily="Lato"
                fontWeight="bold"
                fontSize={5}
                lineHeight={3}
                mr={5}
              >
                {productLogo}
              </Primitives.Text>
            </a>
          </Primitives.Flex>
          {user && user["demo_account"] && user["demo_account"] && (
            <Primitives.Text
              color={colors.text[1]}
              display={["none", "none", "flex"]}
              fontSize={1}
              fontWeight={1}
              letterSpacing={2}
              ml={30}
              mb="5px"
              textAlign="start"
            >
              DEMO
            </Primitives.Text>
          )}
        </Primitives.Flex>
        <Primitives.Flex
          flex={product === "SmartChain" ? 9 : 5}
          justifyContent={justifyContent}
        >
          {data &&
            data.map((item, index) => {
              const pageIndex = index;
              return (
                <Primitives.Box key={index} mx={[1, 1, 1, 1, 2]}>
                  {item.link ? (
                    <Cta
                      color="inherit"
                      fontWeight="inherit"
                      hover={{ color: "inherit", textdecorationline: false }}
                      label={
                        <NavItem
                          color="atomic"
                          hoverColor={colors["highlight-dark"]}
                          height={30}
                          label={item.label}
                          {...(index === 0 ? { px: 1 } : {})}
                          selected={item.selected}
                        />
                      }
                      link={item.link}
                      textdecorationline={false}
                    />
                  ) : (
                    <Primitives.Flex display="inline">
                      <NavItem
                        clickHandler={item.clickHandler && item.clickHandler}
                        collapsed={item.collapsedStatus && item.collapsedStatus}
                        color="atomic"
                        hoverColor={colors["highlight-dark"]}
                        height={30}
                        id={id}
                        label={item.label}
                        options={
                          item.options &&
                          item.options.map((option, index) => {
                            return {
                              glyph: option.glyph,
                              page: [pageIndex, index],
                              text: option.label,
                              link: option.link && option.link,
                              cursor: option.cursor,
                              type: "link"
                            };
                          })
                        }
                        selected={item.selected}
                      />
                    </Primitives.Flex>
                  )}
                </Primitives.Box>
              );
            })}
        </Primitives.Flex>
        <Primitives.Flex
          alignItems="center"
          flex={product === "SmartChain" ? 2 : 4}
          justifyContent="flex-end"
        >
          {navRightComponent}
          <Primitives.Flex
            alignItems="center"
            cursor="pointer"
            ml={3}
            mr={30}
            onClick={() => setUserOptionsCollapse(!userOptionsCollapse)}
            position="relative"
          >
            {loading ? (
              <Primitives.Flex height={25} width={25} mr={6}>
                <Loader onlySpinner />
              </Primitives.Flex>
            ) : (
              <>
                <StyledImage
                  src={
                    user && user.logo
                      ? user.logo
                      : require("../assets/user.svg")
                  }
                />
                <Primitives.Text
                  color={colors.text[0]}
                  fontFamily="Lato"
                  fontSize={1}
                  fontWeight={2}
                  ml={1}
                >
                  {user && user.email}
                </Primitives.Text>
              </>
            )}

            {userOptionsCollapse && (
              <ListDropdown
                color={colors.text[0]}
                fontSize={1}
                fontWeight={1}
                options={profileOptions}
                right={0}
                selectHandler={option => selectHandler(option)}
                top={50}
                width={240}
              />
            )}
          </Primitives.Flex>
        </Primitives.Flex>
      </Primitives.Flex>
    </Primitives.Box>
  );
};

NavigationComponent.propTypes = propTypes;
export default NavigationComponent;
