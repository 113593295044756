import React from "react";

const Checked = ({ ...props }) => (
  <svg
    height="16"
    viewBox="0 0 22 16"
    width="22"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.468 0.319082C21.0426 -0.106361 20.3529 -0.106361 19.9274 0.319082L6.87629 13.3703L1.85966 8.35369C1.43426 7.92824 0.744567 7.92829 0.319082 8.35369C-0.106361 8.77909 -0.106361 9.46878 0.319082 9.89422L6.106 15.6811C6.53128 16.1065 7.22148 16.1062 7.64658 15.6811L21.468 1.85966C21.8934 1.43426 21.8934 0.744525 21.468 0.319082Z"
      fill="inherit"
    />
  </svg>
);
export default Checked;
