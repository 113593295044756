import React from "react";

const UpwardArrow = ({ ...props }) => (
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.42426 0.575736C5.18995 0.341421 4.81005 0.341421 4.57574 0.575736L0.757359 4.39411C0.523045 4.62843 0.523045 5.00833 0.757359 5.24264C0.991674 5.47696 1.37157 5.47696 1.60589 5.24264L5 1.84853L8.39411 5.24264C8.62843 5.47696 9.00833 5.47696 9.24264 5.24264C9.47696 5.00833 9.47696 4.62843 9.24264 4.39411L5.42426 0.575736ZM5.6 12L5.6 1H4.4L4.4 12H5.6Z"
      fill="inherit"
    />
  </svg>
);

export default UpwardArrow;
