import React, { createContext, useState } from "react";
import { node } from "prop-types";

const propTypes = {
  children: node
};

export const FilterContext = createContext();

const FilterContextProvider = ({ children }) => {
  const [filterState, updateFilterState] = useState("");
  return (
    <FilterContext.Provider
      value={{
        filterState: filterState,
        updateFilterState: updateFilterState
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
FilterContextProvider.propTypes = propTypes;

export default FilterContextProvider;
