import React from "react";
import { array, bool, func, number, string } from "prop-types";
import Primitives from "../primitives/index";
import { colors } from "../utils/theme/index";

const propTypes = {
  /** Background color of filter pill */
  backgroundColor: string,
  /** Border radius around the filter pill */
  borderRadius: number,
  /** Handler function for selecting and deselecting pills */
  clickHandler: func,
  /** Boolean if pill is closed or not */
  closed: bool,
  /** Handler function for closing filter pill */
  closeHandler: func,
  /** Text color of filter pill */
  color: string,
  /** Font family of text of filter pill */
  fontFamily: string,
  /** Font size of text of filter pill */
  fontSize: number,
  /** Font style of text of filter pill */
  fontStyle: string,
  /** Font weight of text of filter pill */
  fontWeight: number,
  /** list of tags */
  items: array,
  /** Text which should get rendered on filter pill */
  label: string,
  /** Line height of filter pill */
  lineHeight: number,
  /** Margin right  */
  mr: number,
  /** Margin bottom */
  mb: number,
  /** Horizontal padding of filter pill */
  px: number,
  /** To check if pill is selected or not */
  selected: number
};

const defaultProps = {
  backgroundColor: colors["background-light-blue"],
  borderRadius: 18,
  color: colors.text[1],
  fontFamily: "Lato",
  fontSize: 0,
  fontStyle: "normal",
  fontWeight: 0,
  lineHeight: 3.3,
  px: 2
};

const Tags = ({
  backgroundColor,
  borderRadius,
  clickHandler,
  color,
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  items,
  lineHeight,
  mr,
  mb,
  px,
  selected
}) =>
  items.map((item, index) => (
    <Primitives.Box
      backgroundColor={selected === index ? colors.accent[3] : backgroundColor}
      borderRadius={borderRadius}
      color={selected === index ? "white" : color}
      cursor="pointer"
      display={"inline-flex"}
      fontFamily={fontFamily}
      fontSize={fontSize}
      fontStyle={fontStyle}
      fontWeight={fontWeight}
      key={item.label}
      lineHeight={lineHeight}
      mr={mr}
      mb={mb}
      px={px}
      onClick={() => {
        clickHandler(index, item.key);
      }}
    >
      <Primitives.Text>{item.label}</Primitives.Text>
    </Primitives.Box>
  ));

Tags.propTypes = propTypes;
Tags.defaultProps = defaultProps;

export default Tags;
