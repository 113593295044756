const indianNumberFormat = number => {
  var holder = number.toString();
  var afterDecimal = "";
  if (holder.indexOf(".") > 0)
    afterDecimal = holder.substring(holder.indexOf("."), holder.length);
  holder = Math.floor(number).toString();
  var lastThree = holder.substring(holder.length - 3);
  var otherNumbers = holder.substring(0, holder.length - 3);
  if (otherNumbers !== "") lastThree = "," + lastThree;
  var result =
    otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
    lastThree +
    afterDecimal;
  return result;
};

export default indianNumberFormat;
